<template>
  <div class="table-crud-sistema">
      <div class="row" style="margin-right: 0px; margin-left: 0px;">
        <div class="col" style="text-align:left;">
            <div class="padding">
                <slot name="botaonovo">

                </slot>
            </div>
        </div>
      </div>
    <table :class="classe">
        <thead style="background:#1c4d5a!important;color:white;">
            <tr>
                
                <th v-for="coluna in colunas" :key="coluna.id" style="text-align:center;">
                    {{ coluna.descricaocoluna }}
                </th>
                <th>
                    
                </th>
                <!--<th style="text-align:center;">Ações</th>-->
            </tr>
        </thead>
        <tbody>
            <template v-if="filtros.data && filtros.data.length>0"  >
                <tr v-for="filtro,i in filtros.data" :key="i" >
                    
                    <td v-for="coluna in colunas" :key="coluna.id" :style="'text-align:'+coluna.alinhamentotexto" style="text-align:center;" >
                        {{ (coluna.nomecampo=='semestre' ? (filtro[coluna.nomecampo]==null ? 'Todos' : (filtro[coluna.nomecampo]==0 ? 'Anual' : (filtro[coluna.nomecampo]==1 ? '1º Semestre' : '2º Semestre'))):(coluna.nomecampo=='datainicio' || coluna.nomecampo=='datatermino' ? formatDate(filtro[coluna.nomecampo],2) : filtro[coluna.nomecampo]) )  }}
                    </td>
                    <td :colspan="colunas.length" style="padding-left:15px">
                        <a :href="urlSistema+'/'+filtro.id" v-if="visualizar" class="btn btn-primary btn-sm" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;"><!--Visualizar-->  <b-icon icon="eye-fill" font-scale="1.3"></b-icon></a>&nbsp;&nbsp;
                        <!-- <a :href="urlSistema+'/'+filtro.id+'/edit'" v-if="editar" class="btn btn-primary btn-sm" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;"> <b-icon icon="pencil-fill" font-scale="1.3"></b-icon></a>&nbsp;&nbsp;
                        <a @click="selecionarFiltroExcluir(filtro)" v-if="excluir" class="btn btn-primary btn-sm" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">Apagar  <b-icon icon="trash-fill" font-scale="1.3"></b-icon></a> -->
                    </td> 
                </tr>
            </template>
            <template v-else>
                <tr>
                    <td v-if="colunas && colunas.length" :colspan="colunas.length" style="text-align:center;" >
                        <b>Não existem Filtros para serem exibidos.</b>
                    </td>
                </tr>
            </template>
        </tbody>
    </table>
    <paginate class="padding">
        <li v-for=" link, key in filtros.links" :key="key" :class="(link.active ? 'page-item active':(link.url ? 'page-item':'page-item disabled'))" style="cursor:pointer;">
            <a class="page-link" tabindex="-1" v-html="link.label" @click="paginacao(link)">{{ link.label }}

            </a>
        </li>
    </paginate>
    <modal-exclusao-filtro @exclusaoconcluida="getCursosPse()" v-if="filtroSelecionado" id="ExclusaoFiltro" :url-redirect="'/'+urlSistema+'/'" :url-sistema="urlSistema" :filtro="filtroSelecionado" :sem-padding="true">
        <template v-slot:titulo>
               EXCLUSÃO DE FILTRO
        </template>
    </modal-exclusao-filtro>
  </div>
</template>

<script>
export default {
    props:
    {
        urlSistema:{type:String,required:true},
        novo:{type:Boolean,required:true},
        visualizar:{type:Boolean,required:true},
        editar:{type:Boolean,required:true},
        excluir:{type:Boolean,required:true},
        classe:{
            type:String,
            required:false
        }
    },
    data(){
        return{
            colunas:null,
            filtros:{data:null},
            filtroSelecionado:{},
            urlApi:window.location.origin+'/api/v1'+this.urlSistema,
        }
    },
    created(){
         this.getCursosPse();
    },
    mounted(){
        // this.$root.atualizaCookie('token');
        // console.log(this.filtros)
    },
    methods:{
        paginacao(link){
            if(link.url){
                this.urlApi = link.url; // ajustando a url com parâmetros de pagina
                //  console.log(this.urlApi)
                this.getCursosPse();
            }

        },
        selecionarFiltroExcluir(filtro){
            this.filtroSelecionado = filtro
            //console.log(this.filtroSelecionado  )
            $('#ExclusaoFiltro').modal('show');
            
        },
        excluirPessoa(){
            console.log('teste')
        },
        getCursosPse(){
            // Client/ClientUser
            //gera configurações de cabeçalho para requisição
            var config = {
                method: 'get',
                url: this.urlApi,
                // headers: {
                //         'Accept': 'application/json',
                //         'Authorization': this.$root.token(),
                //     },
                };
                //executa requisição
                axios(config)
                .then(response => {
                    //console.log(response.data.Pessoas)
                    this.filtros = response.data.Filtros
                    this.colunas = response.data.CamposTabelaExibicao
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        formatDate(date,idioma) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            if(idioma==1){
                let dt = [year, month, day].join('-');
                if(dt=='1969-12-31'){
                    return null
                }else{
                    return dt;
                }
            }else if(idioma==2){
                let dt = [day, month, year].join('/');
                if(dt=='31/12/1969'){
                    return null
                }else{
                    return dt;
                }
            }else{
                let dt = [year, month, day].join('-');
                if(dt=='1969-12-31'){
                    return null
                }else{
                    return dt;
                }
            }
        },

    }

}
</script>

<style scoped>
    .table-crud-sistema{
        background: white;
        border-radius: 0px 0px 10px 10px;
        overflow-x: auto;
    }

    table{
        
        overflow: auto;
        white-space: nowrap;
    }
    .padding{
        padding: 15px;
    }

    .page-item.active .page-link{
        background: #1c4d5a;
        border-color: #1c4d5a;
        color: white!important;
    }

    .page-item .page-link{
        color: #1c4d5a;
        border-color: #1c4d5a;
    }

    .btn-primary,.btn-primary:visited{
        background: #1c4d5a;
        color: white;
        border-color: #1c4d5a;
    }

    .btn-primary:hover{
        color: #1c4d5a;
        background: white;
        border-color: #1c4d5a;
    }

    .btn-primary:active{
        color: #1c4d5a!important;
        background: white!important;
        border-color: #1c4d5a!important;
    }

    .page-item.disabled{
        border-color: #1c4d5a;
    }

    @media(max-width: 600px){
        table{
            display: block;    
            overflow: auto;
            white-space: nowrap;
        }
    }
</style>
