<template>
    <div class="modal show" data-backdrop="static" :id="id" tabindex="-1" role="dialog" :aria-labelledby="id+'Label'" aria-hidden="true" @click="redirect()">
        <div class="modal-dialog  modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body" v-if="semPadding" style="padding:0!important">
                    <slot name="conteudo">
                    </slot>
                </div>
                <div class="modal-body" v-else>
                    <slot name="conteudo">
                    </slot>
                </div>
                <div class="modal-footer">
                    <button v-if="urlRedirect" type="button" class="btn btn-secondary" data-dismiss="modal" :id="'btnOk'+id" @click="redirect()">{{LabelBtnFechar}}</button>
                    <button v-else type="button" class="btn btn-secondary" data-dismiss="modal" :id="'btnOk'+id" @click="fecharModal()">{{LabelBtnFechar}}</button>
                    <slot name="botoes">
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['titulo','id','LabelBtnFechar','urlRedirect','semPadding'],
    methods:{
        redirect(){
            if(this.urlRedirect && this.urlRedirect!=''){
                window.location.href = this.urlRedirect;
            }
        },
        fecharModal(){
            this.$emit('fecharmodal');
        }

    }
}
</script>

<style>

</style>
