const { default: axios } = require('axios');
const { toLength } = require('lodash');

window._ = require('lodash');


/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });


// axios.iterceptors.request.use(
//     config => {
// //         console.log('Interceptando request antes do envio: ', config)
//         return config
//     },
//     error =>{
// //         console.log('erro na requisição: ', error)
//         return Promise.reject(error)
//     }
// );

axios.interceptors.request.use(
    function (config) {
        // debugger;
        config.headers['Accept'] = 'application/json'
        
            let token = document.cookie.split(';').find(indice => {
                return indice.includes('token=')
            })
            token = token.split('=')[1]
            token = 'Bearer ' + token
            config.headers.Authorization = token
        

        
        // console.log(token)
       
        console.log('Interceptando request antes do envio: ', config)
        // debugger;
         return config;
    }, 
    function (error) {
        // console.log(error.response)
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    function (response) {
        console.log('Interceptando a resposta', response)
        return response;
    }, 
    function (error) {
        console.log(error.response.data)
        if(error.response && error.response.status == 401 && error.response.data.message=='Unauthenticated.'){
            console.log(error.response.data)
            
            let config = {
                method: 'post',
                url: '/api/v1/auth/refresh',
                // headers: { 
                    //   'Accept': 'application/json', 
                    //   'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC8xMjcuMC4wLjE6ODAwMFwvYXBpXC9sb2dpbiIsImlhdCI6MTY1ODA2Njg0MiwiZXhwIjoxNjU4MDY2OTAyLCJuYmYiOjE2NTgwNjY4NDIsImp0aSI6IjBER0U4clg1UVBva21oSWgiLCJzdWIiOjQ4MSwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.RaCMuAUUdaeWZYLqPD39sSXl5u4wsfgnx-goMdOVeNw'
                    // }
                }
                
                axios(config)
                .then(function (response) {
                    document.cookie = 'token=' + response.data.access_token
                    console.log('config',config)
                    window.location.reload()
                })
                .catch(function (errors) {
                    // console.log(errors.response);
                    return errors
                });
            
        }

            return Promise.reject(error);

    }
);

