/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
import {BootstrapVue,BootstrapVueIcons} from 'bootstrap-vue' //Importing
import VueTheMask from 'vue-the-mask'
import axios from 'axios';
import VueEditor from "vue2-editor";
import DatePicker from 'vue2-datepicker';
import Vuex from 'vuex';
import 'vue2-datepicker/index.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { Bar } from 'vue-chartjs'
import { faHatWizard } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'vue2-datepicker/locale/pt-br';
import money from 'v-money'

import {RotateSquare5,RotateSquare2,Socket} from 'vue-loading-spinner'

// import {Socket} from 'vue-loading-spinner'
// import {Socket} from 'vue-loading-spinner'
// import {Socket} from 'vue-loading-spinner'
// import {Socket} from 'vue-loading-spinner'
import Vue from 'vue';

import './vue.config';

// library.add(FontAwesomeIcon)
window.Vue = require('vue').default;
// Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(BootstrapVue) // Telling Vue to use this in whole application
Vue.use(BootstrapVueIcons)
Vue.use(VueTheMask)
Vue.use(money, {precision: 4})
Vue.component(Bar);
Vue.use( VueEditor );
Vue.use( DatePicker );

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

/**
 * Componentes comuns
*/
Vue.component('rotate-square2',RotateSquare2);
Vue.component('rotate-square5',RotateSquare5);
Vue.component('socket',Socket);
Vue.component('login-component',require('./components/Login/LoginComponent.vue').default);
Vue.component('content-component', require('./components/ContentComponent.vue').default);
Vue.component('item-menu', require('./components/ItemMenu.vue').default);
Vue.component('migalhas-component',require('./components/Migalhas.vue').default);
Vue.component('paginate',require('./components/PaginateComponent.vue').default);
Vue.component('modal-component',require('./components/ModalComponent.vue').default);
Vue.component('loading',require('./components/Loading.vue').default);
Vue.component('font-awesome-icon', FontAwesomeIcon);
/**
 * Componentes diretamente relacionados com empresas e unidades
*/
Vue.component('crud-company',require('./components/Company/CrudCompany.vue').default);
Vue.component('table-company',require('./components/Company/TableCompany.vue').default);
Vue.component('crud-unity',require('./components/Unity/CrudUnity.vue').default);
Vue.component('crud-config',require('./components/Unity/CrudConfig.vue').default);

Vue.component('table-unity',require('./components/Unity/TableUnity.vue').default);
Vue.component('company-unity-modal-exclusao',require('./components/Company/ModalExclusaoCompanyUnity.vue').default);
Vue.component('menu-usuario-unidade',require('./components/Unity/MenuUsuarioUnidade.vue').default);

//CARD MASCARAS
Vue.component('aviso-mascaras',require('./components/CardMascarasOfertas.vue').default);


/**
 * Componentes diretamente relacionados com usuarios
*/
Vue.component('crud-user',require('./components/User/CrudUser.vue').default);
Vue.component('table-user',require('./components/User/TableUser.vue').default);
Vue.component('usuario-modal-exclusao',require('./components/User/ModalExclusaoUser.vue').default);


/**
 * Componentes diretamente relacionados a pagina Home
*/
Vue.component('empresas-unidades',require('./components/Home/HomeUnidades.vue').default);

/**
 * Subscription Web
 */

Vue.component('exportar-ofertas-inscricoes',require('./components/Exportacoes/ExportarOfertaInscricaoWeb.vue').default);
Vue.component('exportar-ofertas-processo-seletivo',require('./components/Exportacoes/ConfigurarOfertaProcessoSeletivo.vue').default);
Vue.component('crud-course',require('./components/Course/CrudCourse.vue').default);
Vue.component('crud-selective-process',require('./components/Selectiveprocess/CrudSelectiveProcess.vue').default);
Vue.component('crud-subscriptionweb',require('./components/Subscriptionweb/CrudSubscriptionweb.vue').default);

Vue.component('captacao-oferta',require('./components/Captacao/CaptacaoOferta.vue').default);
Vue.component('captacao-unidade',require('./components/Captacao/CaptacaoUnidade.vue').default);
Vue.component('lixeira-captacao',require('./components/Captacao/LixeiraCaptacao.vue').default);
Vue.component('dados-captacao',require('./components/Captacao/DadosCaptacao.vue').default);

Vue.component('table-subscriptionweb',require('./components/Subscriptionweb/TableSubscriptionweb.vue').default);
Vue.component('table-selectiveprocess',require('./components/Selectiveprocess/TableSelectiveprocess.vue').default);

Vue.component('modal-exclusao-filtro',require('./components/Subscriptionweb/ModalExclusaoFiltro.vue').default);



Vue.component('table-chamados',require('./components/TableChamados.vue').default);
Vue.component('ficha-chamados',require('./components/FichaChamados.vue').default);


Vue.component('modal-exclusao-services-parts',require('./components/ModalExclusaoServicesParts.vue').default);
Vue.component('form-group-component',require('./components/FormGroupComponent.vue').default);

//COMPONENTE DASHBOARD
Vue.component('card-dashboard',require('./components/Dashboard/CardDashBoard.vue').default);
Vue.component('configuracao-financeira-unidade',require('./components/Configuracao/ConfiguracaoFinanceiraUnidade.vue').default);
Vue.component('embed-component',require('./components/EmbedComponent.vue').default);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const store =new Vuex.Store({
    state: {
        teste:'Teste de Vuex'
    }
})

const app = new Vue({
    el: '#app',
    store,
    axios,
    methods: {
        token(){
            let token = document.cookie.split(';').find(indice => {	return indice.startsWith('token=')})
            token = token.split('=')[1]
            token = 'Bearer ' + token

            return token
        },
        excluirCookie(nome){
            // Cria uma data no passado 01/01/2010
            var data = new Date(2010,1,1);
            // Converte a data para GMT
            data = data.toGMTString();
            // Tenta modificar o valor do cookie para a data expirada
            // Assim ele será apagado
            document.cookie = nome + '=; expires=' + data + '; path=/';
        },
        atualizaCookie(name){
            var config = {
            method: 'post',
            url: '/api/refresh',
            headers: {
                    'Accept': 'application/json',
                    'Authorization': this.token(),
                },
            };
            //executa requisição
            axios(config)
            .then(response => response.data)
            .then(data => {
                // console.log(response.data.usuario)
                if(data.access_token){
                     this.excluirCookie('token')
                     document.cookie='token='+data.access_token
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        },
        numberToReal(numero){
            numero = numero.toFixed(2).split('.')
            numero[0] = 'R$ ' + numero[0].split(/(?=(?:...)*$)/).join('.')
            return numero.join(',')
        }
    }
});
