<template style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">

    <div class="text-center" style="margin-top: 15px;-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;" >
        
        <div class="row">
            <div class="col md-12 ">
                <h3 style="padding: 10px;">
                    Escolha uma Unidade!<hr style="padding: 0px;">
                    <small class="text-muted">Para administrar suas funcionalidades.</small>
                </h3>
            </div>
        </div>
        <div class="row" style="flex-wrap: wrap;">
            <div class="col md-2" v-for=" unidade_empresa, key in empresasUnidades.data" :key="key" style="min-height: 75px; max-height: 100%; height: 100%; width: 100%; min-width: 173px;max-width: 100%;">
                
                    
                        <a class="page-link " :href="'/Access/Unity/Unitie/'+unidade_empresa.id" style="vertical-align: middle;height: 100%!important; width: 100%!important;padding: 20px;margin:15px;text-overflow: clip" tabindex="-1">{{ quebraTextoIfen(unidade_empresa.nomecompleto) }}<br/>( {{ unidade_empresa.companies.codigoempresa }} - {{ unidade_empresa.codigounidade }} )</a>
                    
                

            </div>
        </div>
        
        <paginate class="padding text-center">
            <li v-for=" link, key in empresasUnidades.links" :key="key" :class="(link.active ? 'page-item active':(link.url ? 'page-item':'page-item disabled'))" style="cursor:pointer;">
                <a class="page-link" tabindex="-1" v-html="link.label" @click="paginacao(link)">{{ link.label }}        </a>
            </li>
        </paginate>
        <loading :id="'GetUnidades'" titulo="Obtendo lista de Unidades, Aguarde..." :sem-padding="true" gif-carregando="/imagens/carregando.gif"></loading>
      </div>

</template>

<script>
export default {
    props:{
        // empresasUnidades:{
        //     type:Object,
        //     required:true
        // }
        urlUnities:{type:String,required:true},
        
    },
    data(){
        return{
            empresasUnidades:{
                type:Object,
                required:true
            },
            //urlUnidades:window.location.origin+'/api/v1'+this.urlUnities,
        }
    },
    methods:{
        quebraTextoIfen(texto){
            let array = texto.split('-');
            let concatenar = '';
            
            if(array.length>0){
                //window.alert(array.length)
                for(let i=0;i<array.length;i++){
                    
                    if(i==0){
                        
                        let arrayAux = array[i].trim().split(' ');
                        
                        // console.log('arrayAux',arrayAux);
    
                        if(arrayAux.length>0){
    
                            for(let j=0; j<arrayAux.length; j++){
    
                                if(j<(arrayAux.length-1)){
                                    concatenar = concatenar + arrayAux[j].substr(0,1).trim() 
                                }else{
                                    concatenar = concatenar + arrayAux[j].substr(0,1).trim()+' - '
                                }
    
                            }
    
                        }

                    }else{
                        concatenar = concatenar + array[i].trim()
                    }


                }
            }else{
                concatenar = texto
            }
            
            return concatenar
        },
        paginacao(link){
            if(link.url){
                this.urlUnidades = link.url; // ajustando a url com parâmetros de pagina
                //  console.log(this.urlUnidades)
                this.getUnities();
                console.log(link.url)
            }

        },
        getUnities(){
            // Client/ClientUser
            //gera configurações de cabeçalho para requisição
            console.log('URL: ' + this.urlUnidades)
            $('#GetUnidades').modal('show');
            var config = {
                method: 'get',
                url: this.urlUnidades,
                // headers: {
                //         'Accept': 'application/json',
                //         'Authorization': this.$root.token(),
                //     },
                };
                //executa requisição
                axios(config)
                .then(response => {
                     console.log(response.data.Pessoas)
                    this.empresasUnidades = response.data.Pessoas
                    $('#GetUnidades').modal('hide');
                })
                .catch(function (error) {
                    console.log(error);
                    $('#GetUnidades').modal('hide');
                });
                console.log(window.location.origin+'/api/v1'+this.urlUnities)
            },
    },
    created(){
        this.urlUnidades=window.location.origin+'/api/v1'+this.urlUnities+'/All',
        this.getUnities();
    }
}
</script>

<style scoped>
    a:hover{
        /* background-color: #1c4d5a!important; */
        background-image: linear-gradient( to right, #1c4d5a, #1a7580,#1c4d5a );
        color:white;
        font-weight: bolder;
    }

    .col{
        margin: 2px;
    }
    /* .row{
        margin-bottom: 25px;
    }

    .col,.card{
        border: 1px solid #1c4d5a;
        border-radius: 5px;
        margin:5px;
        box-shadow: 5px 5px 5px grey;
        height: auto;
        
    }

    .crd-width{
        width: 24rem;
    }
    
    @media(min-width:300px) and (max-width:539px){
        .crd-width{
            width: 100%;
        }    
    }

    @media(min-width:540px) and (max-width:767px){
        .crd-width{
            width: 48%;
        }    
    }

    @media(min-width:768px) and (max-width:959px){
        .crd-width{
            width: 48%;
        }    
    }

    @media(min-width:960px) and (max-width:1024px){
        .crd-width{
            width: 18rem;
        } 
        
        h5{
            font-size: 11px!important;
        }
    }

    @media(min-width:1025px) and (max-width:1280px){
        .crd-width{
            width: 22rem;
        } 
        
        
    } */

/* 
    (320x480) iPhone (Original, 3G, 3GS)
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        
    }
    
    (320x480) Smartphone, Portrait
    @media only screen and (device-width: 320px) and (orientation: portrait) {
        
    }
    
    (320x480) Smartphone, Landscape
    @media only screen and (device-width: 480px) and (orientation: landscape) {
        
    }
    
    (480x800) Android
    @media only screen and (min-device-width: 480px) and (max-device-width: 800px) {
        
    }
    
    (640x960) iPhone 4 & 4S
    @media only screen and (min-device-width: 640px) and (max-device-width: 960px) {
        
    }
    
    (720x1280) Galaxy Nexus, WXGA
    @media only screen and (min-device-width: 720px) and (max-device-width: 1280px) {
        
    }
    
    (720x1280) Galaxy Nexus, Landscape
    @media only screen and (min-device-width: 720px) and (max-device-width: 1280px) and (orientation: landscape) {
        
    }
    
    (1024x768) iPad 1 & 2, XGA
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        
    }
    
    (768x1024) iPad 1 & 2, Portrait
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        
    }
    
    (1024x768) iPad 1 & 2, Landscape
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
        
    }
    
    (2048x1536) iPad 3
    @media only screen and (min-device-width: 1536px) and (max-device-width: 2048px) {
        
    }
    
    (1280x720) Galaxy Note 2, WXGA
    @media only screen and (min-device-width: 720px) and (max-device-width: 1280px) {
        
    }
    
    (1366x768) WXGA Display
    @media  screen and (max-width: 1366px) {
        
    }
    
    (1280x1024) SXGA Display
    @media  screen and (max-width: 1280px) {
        
    }
    
    (1440x900) WXGA+ Display
    @media  screen and (max-width: 1440px) {
        
    }
    
    (1680x1050) WSXGA+ Display
    @media  screen and (max-width: 1680px) {
        
    }
    
    (1920x1080) Full HD Display
    @media  screen and (max-width: 1920px) {
        
    }
    
    (1600x900) HD+ Display
    @media  screen and (max-width: 1600px) {
        
    } */


</style>