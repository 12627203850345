<template>
  <div class="migalhas" v-if="perfil_usuario!=''">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li v-if="perfil_usuario==1" class="breadcrumb-item active" aria-current="page"><b>{{ titulo }}</b></li>
            <li v-else-if="perfil_usuario==2" class="breadcrumb-item active" aria-current="page"><b>{{ titulo }}</b></li>
            <li v-else-if="perfil_usuario==3" class="breadcrumb-item active" aria-current="page"><b>{{ titulo }}</b></li>
            <li v-else class="breadcrumb-item active" aria-current="page"><b> OPS</b></li>

        </ol>
    </nav>
  </div>
</template>

<script>
export default {
    props:['titulo','perfil_usuario']

}
</script>

<style>
    .migalhas{
        padding-bottom: 15px;
    }

    @media(max-width:600px){
        .migalhas{
            padding-bottom: 5px;
        }   
    }
</style>