<template>
    <div>
        <div class="div-crud-sistema" v-if="inscricaoAluno!=null">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li v-if="inscricaoAluno.Pessoa && inscricaoAluno.Pessoa!=0" class="nav-item" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <a class="nav-link active" id="aluno-tab" data-toggle="tab" href="#aluno" role="tab" aria-controls="aluno" aria-selected="true">Aluno</a>
                </li>
                <li v-if="inscricaoAluno.Pai && inscricaoAluno.Pai!=0" class="nav-item" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <a class="nav-link" id="pai-tab" data-toggle="tab" href="#pai" role="tab" aria-controls="pai" aria-selected="false">Pai</a>
                </li>
                <li v-if="inscricaoAluno.Mae && inscricaoAluno.Mae!=0" class="nav-item" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <a class="nav-link" id="mae-tab" data-toggle="tab" href="#mae" role="tab" aria-controls="mae" aria-selected="false">Mãe</a>
                </li>
                <li v-if="inscricaoAluno.RespEducacional && inscricaoAluno.RespEducacional!=0" class="nav-item" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <a class="nav-link" id="respedu-tab" data-toggle="tab" href="#respedu" role="tab" aria-controls="respedu" aria-selected="false">Resp. Educacional</a>
                </li>
                <li v-if="inscricaoAluno.RespFinanceiro && inscricaoAluno.RespFinanceiro!=0" class="nav-item" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <a class="nav-link" id="respfin-tab" data-toggle="tab" href="#respfin" role="tab" aria-controls="respfin" aria-selected="false">Resp. Financeiro</a>
                </li>
                <li v-if="requisicao_status!=null && requisicao_status.data" class="nav-item" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <a class="nav-link" id="statusrequisicao-tab" data-toggle="tab" href="#statusrequisicao" role="tab" aria-controls="statusrequisicao" aria-selected="false">Status Requisição</a>
                </li>
                <li v-if="prospect!=null && prospect.web_hooks && prospect.web_hooks.length>0" class="nav-item" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <a class="nav-link" id="webhooks-tab" data-toggle="tab" href="#webhooks" role="tab" aria-controls="webhooks" aria-selected="false">WebHooks</a>
                </li>
            </ul>
            <div v-if="inscricaoAluno.Pessoa && inscricaoAluno.Pessoa!=0" class="tab-content" id="myTabContent" >
                <div v-if="inscricaoAluno.Pessoa && inscricaoAluno.Pessoa!=0" class="tab-pane fade show active" id="aluno" role="tabpanel" aria-labelledby="aluno-tab" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <form class="dadosCaptacao">
                        <div v-if="requestWebhook.processado==1" class="form-row" >
                            <div class="col-md-12 mb-12">
                                <div v-if="requestWebhook.status=='success' && requestWebhook.json_inscricao!=null && requestWebhook.requisicao_id>0" class="alert alert-success text-center" role="alert" >
                                    <b>Inscrição já sincronizada com o GVCollege</b>
                                </div>
                                <div v-else-if="requestWebhook.requisicao_id>0 && requestWebhook.json_inscricao!=null" class="alert alert-danger text-center" role="alert">
                                    <b>{{ requestWebhook.status }}</b>
                                </div>  

                                <div v-if="/*requestWebhook.status=='success' && requestWebhook.requisicao_id>0 &&*/ requestWebhook.json_valor_matricula && requestWebhook.json_valor_matricula!=''" class="alert alert-success text-center" role="alert" >
                                    <b>Envio do Valor Matricula para o CRM já efetuado</b>
                                </div>

                                <div v-if="/*requestWebhook.status=='success' && requestWebhook.requisicao_id>0 &&*/ requestWebhook.json_parcelamento_matricula && requestWebhook.json_parcelamento_matricula!=''" class="alert alert-success text-center" role="alert" >
                                    <b>Matrícula já sincronizada com o GVCollege</b>
                                </div>

                                <div v-if="/*requestWebhook.status=='success' && requestWebhook.requisicao_id>0 &&*/ requestWebhook.status_post_contrato && requestWebhook.status_post_contrato=='success'" class="alert alert-success text-center" role="alert" >
                                    <b>Contrato já sincronizado com o ERP</b>
                                </div>

                                <div v-if="/*requestWebhook.status=='success' && requestWebhook.requisicao_id>0 &&*/ requestWebhook.status_atualizacao_cadastral && requestWebhook.status_atualizacao_cadastral=='success'" class="alert alert-success text-center" role="alert" >
                                    <b>Atualização Cadastral já sincronizada com o ERP</b>
                                </div>
                                                             
                            </div>
                        </div>
                        <div v-if="requestWebhook.processado==1" class="form-row" >
                            <div class="col-md-12 mb-12">
                                <div v-if="erro && erro.dadosInscricao.message!=''" class="alert alert-danger text-left" role="alert" >
                                    <b>{{ erro.dadosInscricao.message }}</b>
                                </div>
                            </div>
                        </div>
                        <div v-if="requestWebhook.processado==0" class="form-row" >
                            <div class="col-md-12 mb-12">
                                <div v-if="erro && erro.erro == true" class="alert alert-danger text-center" role="alert" v-html="'<b>'+erro.message+'</b>'" >
                                    
                                </div>
                                <div v-if="CriticaInscricaoAluno && CriticaInscricaoAluno != null" :class="'alert alert-'+CriticaInscricaoAluno.tipo_critica+' text-center'" role="alert" v-html="'<b>'+CriticaInscricaoAluno.critica_mensagem+'</b>'">
                                
                                </div>
                                
                            </div>
                        </div>
                        <div v-if="requestWebhook.tipoeventoaviso=='a' && requestWebhook.processado==0 && inscricaoAluno.Contrato.AssinouContrato && inscricaoAluno.Contrato.AssinouContrato!='Sim'" class="form-row" >
                            <div class="col-md-12 mb-12">
                                <div class="alert alert-danger text-center" role="alert">
                                    <b>Não será possível processar este WebHook pois o contrato não está assinado.</b>
                                </div>
                            </div>
                        </div>
                        <div v-if="insereAtualiza.insere_atualiza=='i' && requestWebhook.tipoeventoaviso=='f' && requestWebhook.processado==0 " class="form-row" >
                            <div class="col-md-12 mb-12">
                                <div class="alert alert-danger text-center" role="alert">
                                    <b>Não será possível processar a Atualização Cadastral, pois não identificamos uma Inscrição/Matrícula realizada através de outro WebHook</b>
                                </div>
                            </div>
                        </div>
                        <div class="form-row" style="padding-bottom: 20px;" v-if="prospect!=null && prospect.oferta">
                            <div class="col-md-3">
                                <label for="tipowebhook">TIPO WEBHOOK</label>
                                <input readonly type="text" class="form-control" id="turmaerp" placeholder="ID CRM" :value="(requestWebhook.tipoeventoaviso=='c' ? 'Inscrição Confirmada' : (requestWebhook.tipoeventoaviso=='a' ? 'Contrato Assinado' : (requestWebhook.tipoeventoaviso=='m' ? 'Matriculado' : (requestWebhook.tipoeventoaviso=='v' ? 'Envia Valor CRM' : (requestWebhook.tipoeventoaviso=='f' ? 'Atualização Cadastral' : 'Outros')))))">
                            </div>
                            <div class="col-md-2">
                                <label for="ofertacrm">OFERTA</label>
                                <input readonly type="text" class="form-control" id="ofertacrm" placeholder="ID CRM" :value="(prospect.oferta.codigooferta!=null ? prospect.oferta.codigooferta : prospect.oferta.codigoofertapse)">
                            </div>
                            <div class="col-md-4">
                                <label for="turmaerp">TURMA ERP</label>
                                <input readonly type="text" class="form-control" id="turmaerp" placeholder="ID CRM" v-model="prospect.oferta.descricaocompleta">
                            </div>
                            <div class="col-md-3">
                                <label for="turmaerp">Valor Inscrição</label>
                                <input readonly type="text" class="form-control" id="valoroferta" placeholder="ID CRM" v-model="prospect.oferta.valoroferta">
                            </div>
                        </div>
                        
                        <div class="form-row">
                            <div class="col-md-2 mb-2" v-if="prospect!=null && prospect.inscrito">
                                <label for="idcrm">ID CRM</label>
                                <input readonly type="text" class="form-control" id="idcrm" placeholder="ID CRM" v-model="prospect.inscrito.id">
                            </div>
                            <div class="col-md-2 mb-2" v-if="prospect!=null && prospect.inscrito">
                                <label for="regcrm">Reg. CRM</label>
                                <input readonly type="text" class="form-control" id="regcrm" placeholder="Reg. CRM" v-model="requestWebhook.registroprocesso">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="nomecompleto">Nome</label>
                                <input readonly type="text" class="form-control" id="nomecompleto" placeholder="Nome Completo" v-model="inscricaoAluno.Pessoa.DadosGerais.Nome">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="nomesocial">Nome Social</label>
                                <input readonly type="text" class="form-control" id="nomesocial" placeholder="Nome Social" v-model="inscricaoAluno.Pessoa.DadosGerais.NomeSocial">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="cpf">CPF</label>
                                <the-mask readonly :masked="true" :mask="['###.###.###-##']" class="form-control" id="cpf" name="cpf" placeholder="CPF" v-model="inscricaoAluno.Pessoa.Documentos.CPF"/>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="datanascimento">Data de Nascimento</label>
                                <the-mask readonly :masked="true" :mask="['##/##/####']" class="form-control" id="datanascimento" name="datanascimento" placeholder="Data de Nascimento" :value="formatDate(inscricaoAluno.Pessoa.DadosGerais.DataNascimento,2)"/>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="genero">Genero</label>
                                <input readonly type="text" class="form-control" id="genero" placeholder="Genero" v-model="inscricaoAluno.Pessoa.DadosGerais.Sexo">
                            </div>
                            <div class="col-md-3 mb-3" v-if="prospect!=null && prospect.inscrito">
                                <label for="estadocivil">Estado Civil</label>
                                <input readonly type="text" class="form-control" id="estadocivil" placeholder="Estado Civil" v-model="prospect.inscrito.estadoCivilNome">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="rg">Rg</label>
                                <input readonly type="text" class="form-control" id="rg" name="rg" placeholder="RG" v-model="inscricaoAluno.Pessoa.Documentos.RG.Numero">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="orgao_expedidor">Orgão Expedidor</label>
                                <input readonly type="text" class="form-control" id="orgao_expedidor" name="orgao_expedidor" placeholder="Orgão Expedidor" v-model="inscricaoAluno.Pessoa.Documentos.RG.OrgaoEmissor">                                
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="data_expedicao_rg">Data Expedição</label>
                                <input readonly type="text" class="form-control" id="data_expedicao_rg" name="data_expedicao_rg" placeholder="Data Expedição" v-model="inscricaoAluno.Pessoa.Documentos.RG.DataExpedicao">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="estado_rg">Estado Rg</label>
                                <input readonly type="text" class="form-control" id="estado_rg" name="estado_rg" placeholder="UF RG" v-model="inscricaoAluno.Pessoa.Documentos.RG.UFEmissor">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4 mb-4">
                                <label for="cidade_nascimento">Cidade Naturalidade</label>
                                <input readonly type="text" class="form-control" id="cidade_nascimento" name="cidade_nascimento" placeholder="Cidade Naturalidade" v-model="inscricaoAluno.Pessoa.DadosGerais.CidadeNascimento">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="estado_nascimento">Estado Naturalidade</label>
                                <input readonly type="text" class="form-control" id="estado_nascimento" name="estado_nascimento" placeholder="Estado Naturalidade" v-model="inscricaoAluno.Pessoa.DadosGerais.UFNascimento">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="pais_nascimento">País Naturalidade</label>
                                <input readonly type="text" class="form-control" id="pais_nascimento" name="pais_nascimento" placeholder="País Naturalidade" v-model="inscricaoAluno.Pessoa.DadosGerais.PaisNascimento">
                            </div>
                        </div>
                        <div v-if="inscricaoAluno.Pessoa.Contatos && inscricaoAluno.Pessoa.Contatos.length>0" class="form-row"  style="padding-bottom: 20px;" >
                            <div v-for="contato, i in inscricaoAluno.Pessoa.Contatos" v-if="contato.TipoContato!=null && !isMail(contato.Contato)" class="col-md-6 mb-6" :key="i">
                                <label for="telefone">Telefone</label>
                                <the-mask readonly :masked="true" :mask="['###########']" class="form-control" id="telefone" name="telefone" placeholder="Telefone" v-model="contato.Contato"/>
                            </div>
                            <div v-for="contato, i in inscricaoAluno.Pessoa.Contatos" v-if="contato.TipoContato!=null && isMail(contato.Contato)" class="col-md-6 mb-6" :key="i">
                                <label for="email">E-mail</label>
                                <input readonly type="text" class="form-control" id="email" placeholder="E-mail" v-model="contato.Contato">
                            </div>
                        </div>
                        <div v-else class="form-row">
                            <div class="col-md-6 mb-6">
                                <label for="telefone">Telefone</label>
                                <the-mask readonly :masked="true" :mask="['###########']" class="form-control" id="telefone" name="telefone" placeholder="TELEFONE"/>
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="email">E-mail</label>
                                <input readonly type="text" class="form-control" id="email" placeholder="E-mail">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-2 mb-2">
                                <label for="cep">CEP</label>
                                <the-mask readonly :masked="true" :mask="['#####-###']" class="form-control" id="cep" name="cep" placeholder="CEP" v-model="inscricaoAluno.Pessoa.Enderecos[0].Cep"/>
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="endereco">Endereço</label>
                                <input readonly type="text" class="form-control" id="endereco" placeholder="Endereco" :value="(inscricaoAluno.Pessoa.Enderecos[0].Logradouro==null ? '' : ( inscricaoAluno.Pessoa.Enderecos[0].Numero==null ? inscricaoAluno.Pessoa.Enderecos[0].Logradouro : inscricaoAluno.Pessoa.Enderecos[0].Logradouro+', '+inscricaoAluno.Pessoa.Enderecos[0].Numero))">
                            </div>
                            
                            <div class="col-md-1 mb-1">
                                <label for="bairro">Bairro</label>
                                <input readonly type="text" class="form-control" id="bairro" placeholder="Bairro" v-model="inscricaoAluno.Pessoa.Enderecos[0].Bairro">
                            </div>
                            <div class="col-md-1 mb-1">
                                <label for="cidade">Cidade</label>
                                <input readonly type="text" class="form-control" id="cidade" placeholder="Cidade" v-model="inscricaoAluno.Pessoa.Enderecos[0].Cidade">
                            </div>
                            <div class="col-md-1 mb-1">
                                <label for="numero">UF</label>
                                <input readonly type="text" class="form-control" id="uf" placeholder="UF" v-model="inscricaoAluno.Pessoa.Enderecos[0].UF">
                            </div>
                            <div class="col-md-1 mb-1">
                                <label for="numero">País</label>
                                <input readonly type="text" class="form-control" id="pais" placeholder="País" v-model="inscricaoAluno.Pessoa.Enderecos[0].Pais">
                            </div>
                        </div>
                        <div class="form-row"  style="overflow-x: auto;padding-top: 15px;">
                                
                        </div>
                        <div class="form-row">
                            <div class="col-md-12 mb-12">
                                <button v-if="requestWebhook.processado==0" class="btn btn-primary btn-sm" @click.prevent="getDadosInscricaoAluno()">Obter Dados Contato CRM</button>
                                <button v-else class="btn btn-primary btn-sm" disabled>Obter Dados Contato CRM</button>
                                <button v-if="insereAtualiza.insere_atualiza=='i' && requestWebhook.tipoeventoaviso!='v' && requestWebhook.tipoeventoaviso!='f' && requestWebhook.processado == 0" class="btn btn-primary btn-sm" @click.prevent="enviarInscricaoProcessamento()">Enviar Inscrição ERP</button>
                                <button v-else class="btn btn-primary btn-sm" disabled>Enviar Inscrição ERP</button>
                                <button v-if="insereAtualiza.insere_atualiza=='a' && requestWebhook.tipoeventoaviso=='m' && requestWebhook.processado==0" class="btn btn-primary btn-sm" @click.prevent="confirmarMatriculaErp()">Confirmar Matrícula ERP</button>
                                <button v-else class="btn btn-primary btn-sm" disabled>Confirmar Matrícula ERP</button>
                                <button v-if="insereAtualiza.insere_atualiza=='a' && requestWebhook.tipoeventoaviso=='a' && requestWebhook.processado==0" class="btn btn-primary btn-sm" @click.prevent="enviarContratoErp()">Enviar Contrato ERP</button>
                                <button v-else class="btn btn-danger btn-sm" disabled>Enviar Contrato ERP</button>
                                <button v-if="requestWebhook.tipoeventoaviso=='v' && requestWebhook.valormatricula==null && configuracaoFinanceira!=null && ((configuracaoFinanceira.etapaenvioeventoaviso=='contratoaceito' && requestWebhook.tipoeventoaviso=='a') || (configuracaoFinanceira.etapaenvioeventoaviso=='inscricaoconfirmada' && requestWebhook.tipoeventoaviso=='c') || (requestWebhook.tipoeventoaviso=='v'))" @click.prevent="dispararEventoValorMatricula()" class="btn btn-danger btn-sm">Enviar Valor Matrícula CRM</button>
                                <button v-else class="btn btn-danger btn-sm" disabled>Enviar Valor Matrícula CRM</button>
                                <button v-if="insereAtualiza.insere_atualiza=='a' && requestWebhook.tipoeventoaviso=='f' && requestWebhook.processado==0" class="btn btn-primary btn-sm" @click.prevent="atualizarCadastroERP()">Atualizar Cadastro ERP</button>
                                <button v-else class="btn btn-danger btn-sm" disabled>Atualizar Cadastro ERP</button>
                                
                                
                            </div>
                        </div>
                    </form>
                    
                </div>
                <div v-if="inscricaoAluno.Pai && inscricaoAluno.Pai!=0" class="tab-pane fade" id="pai" role="tabpanel" aria-labelledby="pai-tab" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <form class="dadosCaptacao">
                        <div class="form-row">
                            <div class="col-md-2 mb-2" v-if="prospect!=null && prospect.pai">
                                <label for="idcrm">ID CRM</label>
                                <input readonly type="text" class="form-control" id="idcrm" placeholder="ID CRM" v-model="prospect.pai.id">
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="nomecompleto">Nome</label>
                                <input readonly type="text" class="form-control" id="nomecompleto" placeholder="Nome Completo" v-model="inscricaoAluno.Pai.DadosGerais.Nome">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="nomesocial">Nome Social</label>
                                <input readonly type="text" class="form-control" id="nomesocial" placeholder="Nome Social" v-model="inscricaoAluno.Pai.DadosGerais.NomeSocial">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="cpf">CPF</label>
                                <the-mask readonly :masked="true" :mask="['###.###.###-##']" class="form-control" id="cpf" name="cpf" placeholder="CPF" v-model="inscricaoAluno.Pai.Documentos.CPF"/>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="datanascimento">Data de Nascimento</label>
                                <the-mask readonly :masked="true" :mask="['##/##/####']" class="form-control" id="datanascimento" name="datanascimento" placeholder="Data de Nascimento" :value="formatDate(inscricaoAluno.Pai.DadosGerais.DataNascimento,2)"/>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="genero">Genero</label>
                                <input readonly type="text" class="form-control" id="genero" placeholder="Genero" v-model="inscricaoAluno.Pai.DadosGerais.Sexo">
                            </div>
                            <div class="col-md-3 mb-3" v-if="prospect!=null && prospect.pai">
                                <label for="estadocivil">Estado Civil</label>
                                <input readonly type="text" class="form-control" id="estadocivil" placeholder="Estado Civil" v-model="prospect.pai.estadoCivilNome">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="rg">Rg</label>
                                <input readonly type="text" class="form-control" id="rg" name="rg" placeholder="RG" v-model="inscricaoAluno.Pai.Documentos.RG.Numero">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="orgao_expedidor">Orgão Expedidor</label>
                                <input readonly type="text" class="form-control" id="orgao_expedidor" name="orgao_expedidor" placeholder="Orgão Expedidor" v-model="inscricaoAluno.Pai.Documentos.RG.OrgaoEmissor">                                
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="data_expedicao_rg">Data Expedição</label>
                                <input readonly type="text" class="form-control" id="data_expedicao_rg" name="data_expedicao_rg" placeholder="Data Expedição" v-model="inscricaoAluno.Pai.Documentos.RG.DataExpedicao">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="estado_rg">Estado Rg</label>
                                <input readonly type="text" class="form-control" id="estado_rg" name="estado_rg" placeholder="UF RG" v-model="inscricaoAluno.Pai.Documentos.RG.UFEmissor">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4 mb-4">
                                <label for="cidade_nascimento">Cidade Naturalidade</label>
                                <input readonly type="text" class="form-control" id="cidade_nascimento" name="cidade_nascimento" placeholder="Cidade Naturalidade" v-model="inscricaoAluno.Pai.DadosGerais.CidadeNascimento">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="estado_nascimento">Estado Naturalidade</label>
                                <input readonly type="text" class="form-control" id="estado_nascimento" name="estado_nascimento" placeholder="Estado Naturalidade" v-model="inscricaoAluno.Pai.DadosGerais.UFNascimento">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="pais_nascimento">País Naturalidade</label>
                                <input readonly type="text" class="form-control" id="pais_nascimento" name="pais_nascimento" placeholder="País Naturalidade" v-model="inscricaoAluno.Pai.DadosGerais.PaisNascimento">
                            </div>
                        </div>
                        <div v-if="inscricaoAluno.Pai.Contatos && inscricaoAluno.Pai.Contatos.length>0" class="form-row"  style="padding-bottom: 20px;" >
                            <div v-for="contato, i in inscricaoAluno.Pai.Contatos" v-if="contato.TipoContato!=null && !isMail(contato.Contato)" class="col-md-6 mb-6" :key="i">
                                <label for="telefone">Telefone</label>
                                <the-mask readonly :masked="true" :mask="['###########']" class="form-control" id="telefone" name="telefone" placeholder="Telefone" v-model="contato.Contato"/>
                            </div>
                            <div v-for="contato, i in inscricaoAluno.Pai.Contatos" v-if="contato.TipoContato!=null && isMail(contato.Contato)" class="col-md-6 mb-6" :key="i">
                                <label for="email">E-mail</label>
                                <input readonly type="text" class="form-control" id="email" placeholder="E-mail" v-model="contato.Contato">
                            </div>
                        </div>
                        <div v-else class="form-row">
                            <div class="col-md-6 mb-6">
                                <label for="telefone">Telefone</label>
                                <the-mask readonly :masked="true" :mask="['###########']" class="form-control" id="telefone" name="telefone" placeholder="TELEFONE"/>
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="email">E-mail</label>
                                <input readonly type="text" class="form-control" id="email" placeholder="E-mail">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-2 mb-2">
                                <label for="cep">CEP</label>
                                <the-mask readonly :masked="true" :mask="['#####-###']" class="form-control" id="cep" name="cep" placeholder="CEP" v-model="inscricaoAluno.Pai.Enderecos[0].Cep"/>
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="endereco">Endereço</label>
                                <input readonly type="text" class="form-control" id="endereco" placeholder="Endereco" :value="(inscricaoAluno.Pai.Enderecos[0].Logradouro==null ? '' : ( inscricaoAluno.Pai.Enderecos[0].Numero==null ? inscricaoAluno.Pai.Enderecos[0].Logradouro : inscricaoAluno.Pai.Enderecos[0].Logradouro+', '+inscricaoAluno.Pai.Enderecos[0].Numero))">
                            </div>
                            
                            <div class="col-md-1 mb-1">
                                <label for="bairro">Bairro</label>
                                <input readonly type="text" class="form-control" id="bairro" placeholder="Bairro" v-model="inscricaoAluno.Pai.Enderecos[0].Bairro">
                            </div>
                            <div class="col-md-1 mb-1">
                                <label for="cidade">Cidade</label>
                                <input readonly type="text" class="form-control" id="cidade" placeholder="Cidade" v-model="inscricaoAluno.Pai.Enderecos[0].Cidade">
                            </div>
                            <div class="col-md-1 mb-1">
                                <label for="numero">UF</label>
                                <input readonly type="text" class="form-control" id="uf" placeholder="UF" v-model="inscricaoAluno.Pai.Enderecos[0].UF">
                            </div>
                            <div class="col-md-1 mb-1">
                                <label for="numero">País</label>
                                <input readonly type="text" class="form-control" id="pais" placeholder="País" v-model="inscricaoAluno.Pai.Enderecos[0].Pais">
                            </div>
                        </div>
                    </form>
                    
                </div>
                <div v-if="inscricaoAluno.Mae && inscricaoAluno.Mae!=0" class="tab-pane fade" id="mae" role="tabpanel" aria-labelledby="mae-tab" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <form class="dadosCaptacao">
                        <div class="form-row">
                            <div class="col-md-2 mb-2" v-if="prospect!=null && prospect.mae">
                                <label for="idcrm">ID CRM</label>
                                <input readonly type="text" class="form-control" id="idcrm" placeholder="ID CRM" v-model="prospect.mae.id">
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="nomecompleto">Nome</label>
                                <input readonly type="text" class="form-control" id="nomecompleto" placeholder="Nome Completo" v-model="inscricaoAluno.Mae.DadosGerais.Nome">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="nomesocial">Nome Social</label>
                                <input readonly type="text" class="form-control" id="nomesocial" placeholder="Nome Social" v-model="inscricaoAluno.Mae.DadosGerais.NomeSocial">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="cpf">CPF</label>
                                <the-mask readonly :masked="true" :mask="['###.###.###-##']" class="form-control" id="cpf" name="cpf" placeholder="CPF" v-model="inscricaoAluno.Mae.Documentos.CPF"/>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="datanascimento">Data de Nascimento</label>
                                <the-mask readonly :masked="true" :mask="['##/##/####']" class="form-control" id="datanascimento" name="datanascimento" placeholder="Data de Nascimento" :value="formatDate(inscricaoAluno.Mae.DadosGerais.DataNascimento,2)"/>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="genero">Genero</label>
                                <input readonly type="text" class="form-control" id="genero" placeholder="Genero" v-model="inscricaoAluno.Mae.DadosGerais.Sexo">
                            </div>
                            <div class="col-md-3 mb-3"  v-if="prospect!=null && prospect.mae">
                                <label for="estadocivil">Estado Civil</label>
                                <input readonly type="text" class="form-control" id="estadocivil" placeholder="Estado Civil" v-model="prospect.mae.estadoCivilNome">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="rg">Rg</label>
                                <input readonly type="text" class="form-control" id="rg" name="rg" placeholder="RG" v-model="inscricaoAluno.Mae.Documentos.RG.Numero">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="orgao_expedidor">Orgão Expedidor</label>
                                <input readonly type="text" class="form-control" id="orgao_expedidor" name="orgao_expedidor" placeholder="Orgão Expedidor" v-model="inscricaoAluno.Mae.Documentos.RG.OrgaoEmissor">                                
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="data_expedicao_rg">Data Expedição</label>
                                <input readonly type="text" class="form-control" id="data_expedicao_rg" name="data_expedicao_rg" placeholder="Data Expedição" v-model="inscricaoAluno.Mae.Documentos.RG.DataExpedicao">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="estado_rg">Estado Rg</label>
                                <input readonly type="text" class="form-control" id="estado_rg" name="estado_rg" placeholder="UF RG" v-model="inscricaoAluno.Mae.Documentos.RG.UFEmissor">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4 mb-4">
                                <label for="cidade_nascimento">Cidade Naturalidade</label>
                                <input readonly type="text" class="form-control" id="cidade_nascimento" name="cidade_nascimento" placeholder="Cidade Naturalidade" v-model="inscricaoAluno.Mae.DadosGerais.CidadeNascimento">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="estado_nascimento">Estado Naturalidade</label>
                                <input readonly type="text" class="form-control" id="estado_nascimento" name="estado_nascimento" placeholder="Estado Naturalidade" v-model="inscricaoAluno.Mae.DadosGerais.UFNascimento">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="pais_nascimento">País Naturalidade</label>
                                <input readonly type="text" class="form-control" id="pais_nascimento" name="pais_nascimento" placeholder="País Naturalidade" v-model="inscricaoAluno.Mae.DadosGerais.PaisNascimento">
                            </div>
                        </div>
                        <div v-if="inscricaoAluno.Mae.Contatos && inscricaoAluno.Mae.Contatos.length>0" class="form-row"  style="padding-bottom: 20px;" >
                            <div v-for="contato, i in inscricaoAluno.Mae.Contatos" v-if="contato.TipoContato!=null && !isMail(contato.Contato)" class="col-md-6 mb-6" :key="i">
                                <label for="telefone">Telefone</label>
                                <the-mask readonly :masked="true" :mask="['###########']" class="form-control" id="telefone" name="telefone" placeholder="Telefone" v-model="contato.Contato"/>
                            </div>
                            <div v-for="contato, i in inscricaoAluno.Mae.Contatos" v-if="contato.TipoContato!=null && isMail(contato.Contato)" class="col-md-6 mb-6" :key="i">
                                <label for="email">E-mail</label>
                                <input readonly type="text" class="form-control" id="email" placeholder="E-mail" v-model="contato.Contato">
                            </div>
                        </div>
                        <div v-else class="form-row">
                            <div class="col-md-6 mb-6">
                                <label for="telefone">Telefone</label>
                                <the-mask readonly :masked="true" :mask="['###########']" class="form-control" id="telefone" name="telefone" placeholder="TELEFONE"/>
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="email">E-mail</label>
                                <input readonly type="text" class="form-control" id="email" placeholder="E-mail">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-2 mb-2">
                                <label for="cep">CEP</label>
                                <the-mask readonly :masked="true" :mask="['#####-###']" class="form-control" id="cep" name="cep" placeholder="CEP" v-model="inscricaoAluno.Mae.Enderecos[0].Cep"/>
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="endereco">Endereço</label>
                                <input readonly type="text" class="form-control" id="endereco" placeholder="Endereco" :value="(inscricaoAluno.Mae.Enderecos[0].Logradouro==null ? '' : ( inscricaoAluno.Mae.Enderecos[0].Numero==null ? inscricaoAluno.Mae.Enderecos[0].Logradouro : inscricaoAluno.Mae.Enderecos[0].Logradouro+', '+inscricaoAluno.Mae.Enderecos[0].Numero))">
                            </div>
                            
                            <div class="col-md-1 mb-1">
                                <label for="bairro">Bairro</label>
                                <input readonly type="text" class="form-control" id="bairro" placeholder="Bairro" v-model="inscricaoAluno.Mae.Enderecos[0].Bairro">
                            </div>
                            <div class="col-md-1 mb-1">
                                <label for="cidade">Cidade</label>
                                <input readonly type="text" class="form-control" id="cidade" placeholder="Cidade" v-model="inscricaoAluno.Mae.Enderecos[0].Cidade">
                            </div>
                            <div class="col-md-1 mb-1">
                                <label for="numero">UF</label>
                                <input readonly type="text" class="form-control" id="uf" placeholder="UF" v-model="inscricaoAluno.Mae.Enderecos[0].UF">
                            </div>
                            <div class="col-md-1 mb-1">
                                <label for="numero">País</label>
                                <input readonly type="text" class="form-control" id="pais" placeholder="País" v-model="inscricaoAluno.Mae.Enderecos[0].Pais">
                            </div>
                        </div>
                    </form>
                    
                </div>
                <div v-if="inscricaoAluno.RespEducacional && inscricaoAluno.RespEducacional!=0" class="tab-pane fade" id="respedu" role="tabpanel" aria-labelledby="respedu-tab" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <form class="dadosCaptacao">
                        <div class="form-row">
                            <div class="col-md-2 mb-2" v-if="prospect!=null && prospect.responsavel">
                                <label for="idcrm">ID CRM</label>
                                <input readonly type="text" class="form-control" id="idcrm" placeholder="ID CRM" v-model="prospect.responsavel.id">
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="nomecompleto">Nome</label>
                                <input readonly type="text" class="form-control" id="nomecompleto" placeholder="Nome Completo" v-model="inscricaoAluno.RespEducacional.DadosGerais.Nome">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="nomesocial">Nome Social</label>
                                <input readonly type="text" class="form-control" id="nomesocial" placeholder="Nome Social" v-model="inscricaoAluno.RespEducacional.DadosGerais.NomeSocial">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="cpf">CPF</label>
                                <the-mask readonly :masked="true" :mask="['###.###.###-##']" class="form-control" id="cpf" name="cpf" placeholder="CPF" v-model="inscricaoAluno.RespEducacional.Documentos.CPF"/>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="datanascimento">Data de Nascimento</label>
                                <the-mask readonly :masked="true" :mask="['##/##/####']" class="form-control" id="datanascimento" name="datanascimento" placeholder="Data de Nascimento" :value="formatDate(inscricaoAluno.RespEducacional.DadosGerais.DataNascimento,2)"/>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="genero">Genero</label>
                                <input readonly type="text" class="form-control" id="genero" placeholder="Genero" v-model="inscricaoAluno.RespEducacional.DadosGerais.Sexo">
                            </div>
                            <div class="col-md-3 mb-3" v-if="prospect!=null && prospect.responsavelfinanceiro">
                                <label for="estadocivil">Estado Civil</label>
                                <input readonly type="text" class="form-control" id="estadocivil" placeholder="Estado Civil" v-model="prospect.responsavelfinanceiro.estadoCivilNome">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="rg">Rg</label>
                                <input readonly type="text" class="form-control" id="rg" name="rg" placeholder="RG" v-model="inscricaoAluno.RespEducacional.Documentos.RG.Numero">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="orgao_expedidor">Orgão Expedidor</label>
                                <input readonly type="text" class="form-control" id="orgao_expedidor" name="orgao_expedidor" placeholder="Orgão Expedidor" v-model="inscricaoAluno.RespEducacional.Documentos.RG.OrgaoEmissor">                                
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="data_expedicao_rg">Data Expedição</label>
                                <input readonly type="text" class="form-control" id="data_expedicao_rg" name="data_expedicao_rg" placeholder="Data Expedição" v-model="inscricaoAluno.RespEducacional.Documentos.RG.DataExpedicao">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="estado_rg">Estado Rg</label>
                                <input readonly type="text" class="form-control" id="estado_rg" name="estado_rg" placeholder="UF RG" v-model="inscricaoAluno.RespEducacional.Documentos.RG.UFEmissor">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4 mb-4">
                                <label for="cidade_nascimento">Cidade Naturalidade</label>
                                <input readonly type="text" class="form-control" id="cidade_nascimento" name="cidade_nascimento" placeholder="Cidade Naturalidade" v-model="inscricaoAluno.RespEducacional.DadosGerais.CidadeNascimento">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="estado_nascimento">Estado Naturalidade</label>
                                <input readonly type="text" class="form-control" id="estado_nascimento" name="estado_nascimento" placeholder="Estado Naturalidade" v-model="inscricaoAluno.RespEducacional.DadosGerais.UFNascimento">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="pais_nascimento">País Naturalidade</label>
                                <input readonly type="text" class="form-control" id="pais_nascimento" name="pais_nascimento" placeholder="País Naturalidade" v-model="inscricaoAluno.RespEducacional.DadosGerais.PaisNascimento">
                            </div>
                        </div>
                        <div v-if="inscricaoAluno.RespEducacional.Contatos && inscricaoAluno.RespEducacional.Contatos.length>0" class="form-row"  style="padding-bottom: 20px;" >
                            <div v-for="contato, i in inscricaoAluno.RespEducacional.Contatos" v-if="contato.TipoContato!=null && !isMail(contato.Contato)" class="col-md-6 mb-6" :key="i">
                                <label for="telefone">Telefone</label>
                                <the-mask readonly :masked="true" :mask="['###########']" class="form-control" id="telefone" name="telefone" placeholder="Telefone" v-model="contato.Contato"/>
                            </div>
                            <div v-for="contato, i in inscricaoAluno.RespEducacional.Contatos" v-if="contato.TipoContato!=null && isMail(contato.Contato)" class="col-md-6 mb-6" :key="i">
                                <label for="email">E-mail</label>
                                <input readonly type="text" class="form-control" id="email" placeholder="E-mail" v-model="contato.Contato">
                            </div>
                        </div>
                        <div v-else class="form-row">
                            <div class="col-md-6 mb-6">
                                <label for="telefone">Telefone</label>
                                <the-mask readonly :masked="true" :mask="['###########']" class="form-control" id="telefone" name="telefone" placeholder="TELEFONE"/>
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="email">E-mail</label>
                                <input readonly type="text" class="form-control" id="email" placeholder="E-mail">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-2 mb-2">
                                <label for="cep">CEP</label>
                                <the-mask readonly :masked="true" :mask="['#####-###']" class="form-control" id="cep" name="cep" placeholder="CEP" v-model="inscricaoAluno.RespEducacional.Enderecos[0].Cep"/>
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="endereco">Endereço</label>
                                <input readonly type="text" class="form-control" id="endereco" placeholder="Endereco" :value="(inscricaoAluno.RespEducacional.Enderecos[0].Logradouro==null ? '' : ( inscricaoAluno.RespEducacional.Enderecos[0].Numero==null ? inscricaoAluno.RespEducacional.Enderecos[0].Logradouro : inscricaoAluno.RespEducacional.Enderecos[0].Logradouro+', '+inscricaoAluno.RespEducacional.Enderecos[0].Numero))">
                            </div>
                            
                            <div class="col-md-1 mb-1">
                                <label for="bairro">Bairro</label>
                                <input readonly type="text" class="form-control" id="bairro" placeholder="Bairro" v-model="inscricaoAluno.RespEducacional.Enderecos[0].Bairro">
                            </div>
                            <div class="col-md-1 mb-1">
                                <label for="cidade">Cidade</label>
                                <input readonly type="text" class="form-control" id="cidade" placeholder="Cidade" v-model="inscricaoAluno.RespEducacional.Enderecos[0].Cidade">
                            </div>
                            <div class="col-md-1 mb-1">
                                <label for="numero">UF</label>
                                <input readonly type="text" class="form-control" id="uf" placeholder="UF" v-model="inscricaoAluno.RespEducacional.Enderecos[0].UF">
                            </div>
                            <div class="col-md-1 mb-1">
                                <label for="numero">País</label>
                                <input readonly type="text" class="form-control" id="pais" placeholder="País" v-model="inscricaoAluno.RespEducacional.Enderecos[0].Pais">
                            </div>
                        </div>
                    </form>
                    
                </div>
                <div v-if="inscricaoAluno.RespFinanceiro && inscricaoAluno.RespFinanceiro!=0" class="tab-pane fade" id="respfin" role="tabpanel" aria-labelledby="respfin-tab" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <form class="dadosCaptacao">
                        <div class="form-row">
                            <div class="col-md-2 mb-2" v-if="prospect!=null && prospect.responsavelfinanceiro">
                                <label for="idcrm">ID CRM</label>
                                <input readonly type="text" class="form-control" id="idcrm" placeholder="ID CRM" v-model="prospect.responsavelfinanceiro.id">
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="nomecompleto">Nome</label>
                                <input readonly type="text" class="form-control" id="nomecompleto" placeholder="Nome Completo" v-model="inscricaoAluno.RespFinanceiro.DadosGerais.Nome">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="nomesocial">Nome Social</label>
                                <input readonly type="text" class="form-control" id="nomesocial" placeholder="Nome Social" v-model="inscricaoAluno.RespFinanceiro.DadosGerais.NomeSocial">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="cpf">CPF</label>
                                <the-mask readonly :masked="true" :mask="['###.###.###-##']" class="form-control" id="cpf" name="cpf" placeholder="CPF" v-model="inscricaoAluno.RespFinanceiro.Documentos.CPF"/>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="datanascimento">Data de Nascimento</label>
                                <the-mask readonly :masked="true" :mask="['##/##/####']" class="form-control" id="datanascimento" name="datanascimento" placeholder="Data de Nascimento" :value="formatDate(inscricaoAluno.RespFinanceiro.DadosGerais.DataNascimento,2)"/>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="genero">Genero</label>
                                <input readonly type="text" class="form-control" id="genero" placeholder="Genero" v-model="inscricaoAluno.RespFinanceiro.DadosGerais.Sexo">
                            </div>
                            <div class="col-md-3 mb-3" v-if="prospect!=null && prospect.responsavelfinanceiro">
                                <label for="estadocivil">Estado Civil</label>
                                <input readonly type="text" class="form-control" id="estadocivil" placeholder="Estado Civil" v-model="prospect.responsavelfinanceiro.estadoCivilNome">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="rg">Rg</label>
                                <input readonly type="text" class="form-control" id="rg" name="rg" placeholder="RG" v-model="inscricaoAluno.RespFinanceiro.Documentos.RG.Numero">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="orgao_expedidor">Orgão Expedidor</label>
                                <input readonly type="text" class="form-control" id="orgao_expedidor" name="orgao_expedidor" placeholder="Orgão Expedidor" v-model="inscricaoAluno.RespFinanceiro.Documentos.RG.OrgaoEmissor">                                
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="data_expedicao_rg">Data Expedição</label>
                                <input readonly type="text" class="form-control" id="data_expedicao_rg" name="data_expedicao_rg" placeholder="Data Expedição" v-model="inscricaoAluno.RespFinanceiro.Documentos.RG.DataExpedicao">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="estado_rg">Estado Rg</label>
                                <input readonly type="text" class="form-control" id="estado_rg" name="estado_rg" placeholder="UF RG" v-model="inscricaoAluno.RespFinanceiro.Documentos.RG.UFEmissor">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4 mb-4">
                                <label for="cidade_nascimento">Cidade Naturalidade</label>
                                <input readonly type="text" class="form-control" id="cidade_nascimento" name="cidade_nascimento" placeholder="Cidade Naturalidade" v-model="inscricaoAluno.RespFinanceiro.DadosGerais.CidadeNascimento">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="estado_nascimento">Estado Naturalidade</label>
                                <input readonly type="text" class="form-control" id="estado_nascimento" name="estado_nascimento" placeholder="Estado Naturalidade" v-model="inscricaoAluno.RespFinanceiro.DadosGerais.UFNascimento">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="pais_nascimento">País Naturalidade</label>
                                <input readonly type="text" class="form-control" id="pais_nascimento" name="pais_nascimento" placeholder="País Naturalidade" v-model="inscricaoAluno.RespFinanceiro.DadosGerais.PaisNascimento">
                            </div>
                        </div>
                        <div v-if="inscricaoAluno.RespFinanceiro.Contatos && inscricaoAluno.RespFinanceiro.Contatos.length>0" class="form-row"  style="padding-bottom: 20px;" >
                            <div v-for="contato, i in inscricaoAluno.RespFinanceiro.Contatos" v-if="contato.TipoContato!=null && !isMail(contato.Contato)" class="col-md-6 mb-6" :key="i">
                                <label for="telefone">Telefone</label>
                                <the-mask readonly :masked="true" :mask="['###########']" class="form-control" id="telefone" name="telefone" placeholder="Telefone" v-model="contato.Contato"/>
                            </div>
                            <div v-for="contato, i in inscricaoAluno.RespFinanceiro.Contatos" v-if="contato.TipoContato!=null && isMail(contato.Contato)" class="col-md-6 mb-6" :key="i">
                                <label for="email">E-mail</label>
                                <input readonly type="text" class="form-control" id="email" placeholder="E-mail" v-model="contato.Contato">
                            </div>
                        </div>
                        <div v-else class="form-row">
                            <div class="col-md-6 mb-6">
                                <label for="telefone">Telefone</label>
                                <the-mask readonly :masked="true" :mask="['###########']" class="form-control" id="telefone" name="telefone" placeholder="TELEFONE"/>
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="email">E-mail</label>
                                <input readonly type="text" class="form-control" id="email" placeholder="E-mail">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-2 mb-2">
                                <label for="cep">CEP</label>
                                <the-mask readonly :masked="true" :mask="['#####-###']" class="form-control" id="cep" name="cep" placeholder="CEP" v-model="inscricaoAluno.RespFinanceiro.Enderecos[0].Cep"/>
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="endereco">Endereço</label>
                                <input readonly type="text" class="form-control" id="endereco" placeholder="Endereco" :value="(inscricaoAluno.RespFinanceiro.Enderecos[0].Logradouro==null ? '' : ( inscricaoAluno.RespFinanceiro.Enderecos[0].Numero==null ? inscricaoAluno.RespFinanceiro.Enderecos[0].Logradouro : inscricaoAluno.RespFinanceiro.Enderecos[0].Logradouro+', '+inscricaoAluno.RespFinanceiro.Enderecos[0].Numero))">
                            </div>
                            
                            <div class="col-md-1 mb-1">
                                <label for="bairro">Bairro</label>
                                <input readonly type="text" class="form-control" id="bairro" placeholder="Bairro" v-model="inscricaoAluno.RespFinanceiro.Enderecos[0].Bairro">
                            </div>
                            <div class="col-md-1 mb-1">
                                <label for="cidade">Cidade</label>
                                <input readonly type="text" class="form-control" id="cidade" placeholder="Cidade" v-model="inscricaoAluno.RespFinanceiro.Enderecos[0].Cidade">
                            </div>
                            <div class="col-md-1 mb-1">
                                <label for="numero">UF</label>
                                <input readonly type="text" class="form-control" id="uf" placeholder="UF" v-model="inscricaoAluno.RespFinanceiro.Enderecos[0].UF">
                            </div>
                            <div class="col-md-1 mb-1">
                                <label for="numero">País</label>
                                <input readonly type="text" class="form-control" id="pais" placeholder="País" v-model="inscricaoAluno.RespFinanceiro.Enderecos[0].Pais">
                            </div>
                        </div>
                    </form>
                    
                </div>
                <div v-if="requisicao_status!=null && requisicao_status.data" class="tab-pane fade" id="statusrequisicao" role="tabpanel" aria-labelledby="statusrequisicao-tab" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <form>
                        <table class="table table-hover table-sm" style="margin-bottom:0px">
                            <thead>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th colspan="5" class="align-middle" style="text-align: center;width: 70px;">
                                        Status Geral do Processamento (ERP)
                                    </th>
                                </tr>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th class="align-middle" style="text-align: center;width: 70px;">
                                        Requisição
                                    </th>
                                    <th class="align-middle" style="text-align: left;">
                                        Nome
                                    </th>
                                    <th class="align-middle" style="text-align: center;width: 100px;">
                                        Data
                                    </th>
                                    <th class="align-middle" style="text-align: center;width: 25px;">
                                        Etapa
                                    </th>
                                    <th class="align-middle" style="text-align: center;width: 25px;">
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="align-middle" style="text-align: center;">
                                        {{ requisicao_status.data.id }}
                                    </td>
                                    <td class="align-middle">
                                        {{ requisicao_status.data.pessoa.nome }}
                                    </td>
                                    <td class="align-middle" style="text-align: center;width:150px;">
                                        {{ daraHoraBrasileiro(requisicao_status.data.created_at) }}
                                    </td>
                                    <td class="align-middle" style="text-transform: capitalize;text-align: center;font-weight: bold;">
                                        
                                        {{ requisicao_status.data.step }}
                                    </td> 
                                    <td :class="'align-middle ' + getEstiloStatusRequisicao(requisicao_status.data.status)" style="text-transform: capitalize;text-align: center;font-weight: bold;border-radius: 0px;">
                                        
                                            {{ getEstyloDescricaoStatusRequisicao(requisicao_status.data.status) }}
                                        
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="table table-hover table-sm" v-if="requisicao_status.data.logs.length>0" style="margin-bottom:0px;">
                            <thead>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th colspan="5" class="align-middle" style="text-align: center;width: 70px;">
                                        Status Processamento Passo-a-Passo (ERP)
                                    </th>
                                </tr>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th class="align-middle" style="text-align: center;width: 70px;">
                                        ID
                                    </th>
                                    <th class="align-middle" style="text-align: left;">
                                        Mensagem
                                    </th>
                                    <th class="align-middle" style="text-align: center;width: 100px;">
                                        Data
                                    </th>
                                    <th class="align-middle" style="text-align: center;width: 25px;">
                                        Etapa
                                    </th>
                                    <th class="align-middle" style="text-align: center;width: 25px;">
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="log, item in requisicao_status.data.logs" :key="item">
                                    <td class="align-middle" style="text-align: center;">
                                        {{ log.id }}
                                    </td>
                                    <td class="align-middle">
                                        {{ log.mensagem }}
                                    </td>
                                    <td class="align-middle" style="text-align: center;width:150px;">
                                        {{ daraHoraBrasileiro(log.created_at) }}
                                    </td>
                                    <td class="align-middle" style="text-transform: capitalize;text-align: center;font-weight: bold;">
                                        {{ requisicao_status.data.step }}
                                    </td>
                                    <td :class="'align-middle ' + getEstiloStatusRequisicao(log.status_id)" style="text-transform: capitalize;text-align: center;font-weight: bold;border-radius: 0px;">
                                        {{ getEstyloDescricaoStatusRequisicao(log.status_id) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </form>
                    
                </div>
                <div v-if="prospect!=null && prospect.web_hooks && prospect.web_hooks.length>0" class="tab-pane fade" id="webhooks" role="tabpanel" aria-labelledby="webhooks-tab" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                   <table class="table table-hover table-sm" style="white-space: normal;border-top: 0px;width:100%;margin-bottom:0px;">
                        <thead>
                            <tr style="background-color: #1c4d5a;color:white;">
                                <th style="text-align: center;">
                                    Ações
                                </th>
                                <th style="text-align: center;width: auto;">
                                    Item
                                </th>
                                <th style="text-align: center;max-width: 120px;width: 120px;">
                                    ID
                                </th>
                                <th style="text-align: center;max-width: 250px;width: 250px;">
                                    Nome
                                </th>
                                <!--th style="text-align: center;max-width: 120px;width: 120px;">
                                    Curso
                                </th-->
                                <th style="text-align: center;max-width: 350px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                                    Turma ERP
                                </th>
                                <th style="text-align: center;text-overflow: ellipsis;">
                                    Requisição ERP
                                </th>
                                <th style="text-align: center;text-overflow: ellipsis;width:10px;overflow: hidden;white-space: nowrap;">
                                    Contato ID CRM
                                </th>
                                <!-- <th style="text-align: center;text-overflow: ellipsis;">
                                    Etapas
                                </th> -->
                                <th style="text-align: center;width: 45px;">
                                    Sinc.
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Data
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Status
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Status
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Origem
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Tipo
                                </th>
                                
                            </tr>
                        </thead>
                        <tbody v-if="prospect.web_hooks.length>0">
                            <template v-for="aluno_captado,item in prospect.web_hooks" >
                                <tr :key="item">
                                    <td style="text-align: center;white-space: nowrap;width:130px!important;max-width:150px!important;" class="align-middle">
                                        <a v-if="webHook.id!=aluno_captado.id" :href="'/Access/Unity/Unitie/'+prospect.oferta.unity_id+'/captacao/wh/'+aluno_captado.id" class="btn btn-primary btn-sm"><b-icon icon="eye-fill" font-scale="1.3"></b-icon></a>
                                        <button v-else class="btn btn-primary btn-sm" disabled><b-icon icon="eye-fill" font-scale="1.3"></b-icon></button>
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{ item+1 }}
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{ aluno_captado.id }}
                                    </td>
                                    <td class="align-middle" style="text-align: left;font-weight: bold;text-overflow: ellipsis;max-width:120px;overflow: hidden;white-space: nowrap;">
                                        {{ aluno_captado.nomeinscrito }}
                                    </td>
                                    
                                    <td class="align-middle" style="text-align: left;font-weight: bold;text-overflow: ellipsis;max-width:200px;overflow: hidden;white-space: nowrap;">
                                        {{ aluno_captado.descricaocompleta }}
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{aluno_captado.requisicao_id}}
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{ (aluno_captado.request_body ? JSON.parse(aluno_captado.request_body).contato.id : '') }}
                                    </td>
                                    
                                    <td style="white-space: normal;text-align: center;" class="align-middle">
                                        <b-icon v-if="aluno_captado.processado == 1" icon="check-lg" font-scale="1.3" style="color:green"></b-icon>
                                        <b-icon v-else icon="x-lg" font-scale="1.3" style="color:red"></b-icon>
                                    </td>
                                    <td style="text-align: center;white-space: nowrap;" class="align-middle">
                                        {{  daraHoraBrasileiro(aluno_captado.created_at) }}
                                    </td>
                                    <td :class="'align-middle ' + getEstiloStatusRequisicao(aluno_captado.status)" style=";white-space: nowrap;padding:5px!important;font-size: 11px!important;text-align: center; width: auto!important;border-radius: 0px;">
                                        
                                            <b>{{ getDescricaoStatusRequisicao(aluno_captado) }}</b>
                                    </td>
                                    <td :class="'align-middle ' + getEstiloStatus(aluno_captado.status)" style=";white-space: nowrap;padding:5px!important;font-size: 11px!important;text-align: center; width: auto!important;border-radius: 0px;">
                                        <b>{{ getEstyloDescricaoStatusRequisicao((aluno_captado.status_inscricao!=null ? aluno_captado.status_inscricao :(aluno_captado.status_valormatricula!=null ? aluno_captado.status_valormatricula : (aluno_captado.status_post_contrato!=null ? aluno_captado.status_post_contrato : (aluno_captado.status_atualizacao_cadastral!=null ? aluno_captado.status_atualizacao_cadastral : aluno_captado.status))) )) }}</b>
                                    </td>
                                    <td style="white-space: normal;text-align: center;" class="align-middle">
                                        <b>{{ aluno_captado.origem_inclusao }}</b>
                                    </td>
                                    <td style="text-align: left;font-weight: bold;text-overflow: ellipsis;max-width:125px;overflow: hidden;white-space: nowrap;" class="align-middle">
                                        <b>{{ ( aluno_captado.tipoeventoaviso=='c' ? "Insc. Confirmada" : ( aluno_captado.tipoeventoaviso=='a' ? "Contrato Assinado" : ( aluno_captado.tipoeventoaviso=='m' ? "Matriculado":( aluno_captado.tipoeventoaviso=='v' ? "Envia Valor CRM":( aluno_captado.tipoeventoaviso=='f' ? "Atualização Cadastral":"Outros" ) ) ) ) ) }}</b>
                                    </td>
                                </tr>

                            </template>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="13" style="text-align: center;font-weight: bold;">
                                    Não existem dados para exibir
                                </td>
                            </tr>
                        </tbody>
                    </table>
                        
                </div>
            </div>
        </div>
        <div class="div-crud-sistema" v-else>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li  class="nav-item" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <a class="nav-link active" id="aluno-tab" data-toggle="tab" href="#aluno" role="tab" aria-controls="aluno" aria-selected="true">Aluno</a>
                </li>
                <li  class="nav-item" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <a class="nav-link" id="pai-tab" data-toggle="tab" href="#pai" role="tab" aria-controls="pai" aria-selected="false">Pai</a>
                </li>
                <li  class="nav-item" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <a class="nav-link" id="mae-tab" data-toggle="tab" href="#mae" role="tab" aria-controls="mae" aria-selected="false">Mãe</a>
                </li>
                <li  class="nav-item" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <a class="nav-link" id="respedu-tab" data-toggle="tab" href="#respedu" role="tab" aria-controls="respedu" aria-selected="false">Resp. Educacional</a>
                </li>
                <li  class="nav-item" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <a class="nav-link" id="respfin-tab" data-toggle="tab" href="#respfin" role="tab" aria-controls="respfin" aria-selected="false">Resp. Financeiro</a>
                </li>
                <li v-if="requisicao_status && requisicao_status.data" class="nav-item" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <a class="nav-link" id="statusrequisicao-tab" data-toggle="tab" href="#statusrequisicao" role="tab" aria-controls="statusrequisicao" aria-selected="false">Status Requisição</a>
                </li>
                <li v-if="prospect && prospect.web_hooks && prospect.web_hooks.length>0" class="nav-item" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <a class="nav-link" id="webhooks-tab" data-toggle="tab" href="#webhooks" role="tab" aria-controls="webhooks" aria-selected="false">WebHooks</a>
                </li>
            </ul>
            <div  class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="aluno" role="tabpanel" aria-labelledby="aluno-tab" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <form class="dadosCaptacao">
                        <div class="form-row">
                            <div class="col-md-12 mb-12">
                                <div class="alert alert-danger text-left" role="alert">
                                    <b>Aguarde, estamos requisitando as Informações ao CRM!</b>
                                </div>

                                <div v-if="erro && erro.dadosInscricao.message!=''" class="alert alert-danger text-left" role="alert" >
                                    <b>{{ erro.dadosInscricao.message }}</b>
                                </div>
                            </div>
                        </div>
                        <div class="form-row" style="padding-bottom: 20px;">
                            <div class="col-md-12">
                                <label for="turmaerp">TURMA ERP</label>
                                <input readonly type="text" class="form-control" id="turmaerp" placeholder="TURMA">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-2 mb-2">
                                <label for="idcrm">ID CRM</label>
                                <input readonly type="text" class="form-control" id="idcrm" placeholder="ID CRM">
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="nomecompleto">Nome</label>
                                <input readonly type="text" class="form-control" id="nomecompleto" placeholder="Nome Completo">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="nomesocial">Nome Social</label>
                                <input readonly type="text" class="form-control" id="nomesocial" placeholder="Nome Social">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="cpf">CPF</label>
                                <the-mask readonly :masked="true" :mask="['###.###.###-##']" class="form-control" id="cpf" name="cpf" placeholder="CPF"/>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="datanascimento">Data de Nascimento</label>
                                <the-mask readonly :masked="true" :mask="['##/##/####']" class="form-control" id="datanascimento" name="datanascimento" placeholder="Data de Nascimento"/>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="genero">Genero</label>
                                <input readonly type="text" class="form-control" id="genero" placeholder="Genero">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="estadocivil">Estado Civil</label>
                                <input readonly type="text" class="form-control" id="estadocivil" placeholder="Estado Civil">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="rg">Rg</label>
                                <input readonly type="text" class="form-control" id="rg" name="rg" placeholder="RG">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="orgao_expedidor">Orgão Expedidor</label>
                                <input readonly type="text" class="form-control" id="orgao_expedidor" name="orgao_expedidor" placeholder="Orgão Expedidor">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="data_expedicao_rg">Data Expedição</label>
                                <input readonly type="text" class="form-control" id="data_expedicao_rg" name="data_expedicao_rg" placeholder="Data Expedição">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="cidade_rg">Cidade - Estado Rg</label>
                                <input readonly type="text" class="form-control" id="cidade_rg" name="cidade_rg" placeholder="Cidade RG">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4 mb-4">
                                <label for="cidade_nascimento">Cidade Naturalidade</label>
                                <input readonly type="text" class="form-control" id="cidade_nascimento" name="cidade_nascimento" placeholder="Cidade Naturalidade">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="estado_nascimento">Estado Naturalidade</label>
                                <input readonly type="text" class="form-control" id="estado_nascimento" name="estado_nascimento" placeholder="Estado Naturalidade">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="pais_nascimento">País Naturalidade</label>
                                <input readonly type="text" class="form-control" id="pais_nascimento" name="pais_nascimento" placeholder="País Naturalidade">
                            </div>
                        </div>
                        <div class="form-row"  style="padding-bottom: 20px;">
                            <div class="col-md-6 mb-6">
                                <label for="telefone">Telefone</label>
                                <the-mask readonly :masked="false" :mask="['###########']" class="form-control" id="telefone" name="telefone" placeholder="Telefone"/>
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="email">E-mail</label>
                                <input readonly type="text" class="form-control" id="email" placeholder="E-mail">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="cep">CEP</label>
                                <the-mask readonly :masked="true" :mask="['#####-###']" class="form-control" id="cep" name="cep" placeholder="CEP"/>
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="endereco">Endereço</label>
                                <input readonly type="text" class="form-control" id="endereco" placeholder="Endereco">
                            </div>
                            <div class="col-md-1 mb-1">
                                <label for="numero">Número</label>
                                <input readonly type="text" class="form-control" id="numero" placeholder="Nº">
                            </div>
                            <div class="col-md-2 mb-2">
                                <label for="bairro">Bairro</label>
                                <input readonly type="text" class="form-control" id="bairro" placeholder="Cidade - Estado">
                            </div>
                            <div class="col-md-2 mb-2">
                                <label for="cidadeestado">Cidade - Estado</label>
                                <input readonly type="text" class="form-control" id="cidadeestado" placeholder="Cidade - Estado">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-12 mb-12">
                                <button v-if="requestWebhook.processado==0" class="btn btn-primary btn-sm" @click.prevent="enviarInscricaoProcessamento()">Enviar Inscrição ERP</button>
                                <button v-else class="btn btn-primary btn-sm" disabled>Enviar Inscrição ERP</button>
                            </div>
                        </div>
                    </form>
                    
                </div>
                <div v-if="prospect && prospect.pai" class="tab-pane fade" id="pai" role="tabpanel" aria-labelledby="pai-tab" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <form class="dadosCaptacao">
                        <div class="form-row">
                            <div class="col-md-2 mb-2">
                                <label for="idcrm">ID CRM</label>
                                <input readonly type="text" class="form-control" id="idcrm" placeholder="ID CRM">
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="nomecompleto">Nome</label>
                                <input readonly type="text" class="form-control" id="nomecompleto" placeholder="Nome Completo">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="nomesocial">Nome Social</label>
                                <input readonly type="text" class="form-control" id="nomesocial" placeholder="Nome Social">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="cpf">CPF</label>
                                <the-mask readonly :masked="true" :mask="['###.###.###-##']" class="form-control" id="cpf" name="cpf" placeholder="CPF"/>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="datanascimento">Data de Nascimento</label>
                                <the-mask readonly :masked="true" :mask="['##/##/####']" class="form-control" id="datanascimento" name="datanascimento" placeholder="Data de Nascimento"/>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="genero">Genero</label>
                                <input readonly type="text" class="form-control" id="genero" placeholder="Genero">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="estadocivil">Estado Civil</label>
                                <input readonly type="text" class="form-control" id="estadocivil" placeholder="Estado Civil">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="rg">Rg</label>
                                <input readonly type="text" class="form-control" id="rg" name="rg" placeholder="RG">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="orgao_expedidor">Orgão Expedidor</label>
                                <input readonly type="text" class="form-control" id="orgao_expedidor" name="orgao_expedidor" placeholder="Orgão Expedidor" />
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="data_expedicao_rg">Data Expedição</label>
                                <input readonly type="text" class="form-control" id="data_expedicao_rg" name="data_expedicao_rg" placeholder="Data Expedição">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="cidade_rg">Cidade - Estado Rg</label>
                                <input readonly type="text" class="form-control" id="cidade_rg" name="cidade_rg" placeholder="Cidade RG" :value="prospect.pai.cidade_rg + ' - ' + prospect.pai.uf_rg">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4 mb-4">
                                <label for="cidade_nascimento">Cidade Naturalidade</label>
                                <input readonly type="text" class="form-control" id="cidade_nascimento" name="cidade_nascimento" placeholder="Cidade Naturalidade">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="estado_nascimento">Estado Naturalidade</label>
                                <input readonly type="text" class="form-control" id="estado_nascimento" name="estado_nascimento" placeholder="Estado Naturalidade">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="pais_nascimento">País Naturalidade</label>
                                <input readonly type="text" class="form-control" id="pais_nascimento" name="pais_nascimento" placeholder="País Naturalidade">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-6 mb-6">
                                <label for="telefone">Telefone</label>
                                <the-mask readonly :masked="false" :mask="['###########']" class="form-control" id="telefone" name="telefone" placeholder="TELEFONE"/>
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="email">E-mail</label>
                                <input readonly type="text" class="form-control" id="email" placeholder="E-mail">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="cep">CEP</label>
                                <the-mask readonly :masked="true" :mask="['#####-###']" class="form-control" id="cep" name="cep" placeholder="CEP"/>
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="endereco">Endereço</label>
                                <input readonly type="text" class="form-control" id="endereco" placeholder="Endereco">
                            </div>
                            <div class="col-md-1 mb-1">
                                <label for="numero">Número</label>
                                <input readonly type="text" class="form-control" id="numero" placeholder="Nº">
                            </div>
                            <div class="col-md-2 mb-2">
                                <label for="bairro">Bairro</label>
                                <input readonly type="text" class="form-control" id="bairro" placeholder="Cidade - Estado">
                            </div>
                            <div class="col-md-2 mb-2">
                                <label for="cidadeestado">Cidade - Estado</label>
                                <input readonly type="text" class="form-control" id="cidadeestado" placeholder="Cidade - Estado">
                            </div>
                        </div>
                    </form>
                    
                </div>
                <div v-if="prospect && prospect.mae" class="tab-pane fade" id="mae" role="tabpanel" aria-labelledby="mae-tab" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <form class="dadosCaptacao">
                        <div class="form-row">
                            <div class="col-md-2 mb-2">
                                <label for="idcrm">ID CRM</label>
                                <input readonly type="text" class="form-control" id="idcrm" placeholder="ID CRM">
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="nomecompleto">Nome</label>
                                <input readonly type="text" class="form-control" id="nomecompleto" placeholder="Nome Completo">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="nomesocial">Nome Social</label>
                                <input readonly type="text" class="form-control" id="nomesocial" placeholder="Nome Social">
                            </div>
                            
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="cpf">CPF</label>
                                <the-mask readonly :masked="true" :mask="['###.###.###-##']" class="form-control" id="cpf" name="cpf" placeholder="CPF"/>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="datanascimento">Data de Nascimento</label>
                                <the-mask readonly :masked="true" :mask="['##/##/####']" class="form-control" id="datanascimento" name="datanascimento" placeholder="Data de Nascimento"/>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="genero">Genero</label>
                                <input readonly type="text" class="form-control" id="genero" placeholder="Genero">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="estadocivil">Estado Civil</label>
                                <input readonly type="text" class="form-control" id="estadocivil" placeholder="Estado Civil">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="rg">Rg</label>
                                <input readonly type="text" class="form-control" id="rg" name="rg" placeholder="RG">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="orgao_expedidor">Orgão Expedidor</label>
                                <input readonly type="text" class="form-control" id="orgao_expedidor" name="orgao_expedidor" placeholder="Orgão Expedidor">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="data_expedicao_rg">Data Expedição</label>
                                <input readonly type="text" class="form-control" id="data_expedicao_rg" name="data_expedicao_rg" placeholder="Data Expedição">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="cidade_rg">Cidade - Estado Rg</label>
                                <input readonly type="text" class="form-control" id="cidade_rg" name="cidade_rg" placeholder="Cidade RG" :value="prospect.mae.cidade_rg + ' - ' + prospect.mae.uf_rg">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4 mb-4">
                                <label for="cidade_nascimento">Cidade Naturalidade</label>
                                <input readonly type="text" class="form-control" id="cidade_nascimento" name="cidade_nascimento" placeholder="Cidade Naturalidade">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="estado_nascimento">Estado Naturalidade</label>
                                <input readonly type="text" class="form-control" id="estado_nascimento" name="estado_nascimento" placeholder="Estado Naturalidade">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="pais_nascimento">País Naturalidade</label>
                                <input readonly type="text" class="form-control" id="pais_nascimento" name="pais_nascimento" placeholder="País Naturalidade">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-6 mb-6">
                                <label for="telefone">Telefone</label>
                                <the-mask readonly :masked="false" :mask="['###########']" class="form-control" id="telefone" name="telefone" placeholder="TELEFONE"/>
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="email">E-mail</label>
                                <input readonly type="text" class="form-control" id="email" placeholder="E-mail">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="cep">CEP</label>
                                <the-mask readonly :masked="true" :mask="['#####-###']" class="form-control" id="cep" name="cep" placeholder="CEP"/>
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="endereco">Endereço</label>
                                <input readonly type="text" class="form-control" id="endereco" placeholder="Endereco">
                            </div>
                            <div class="col-md-1 mb-1">
                                <label for="numero">Número</label>
                                <input readonly type="text" class="form-control" id="numero" placeholder="Nº">
                            </div>
                            <div class="col-md-2 mb-2">
                                <label for="bairro">Bairro</label>
                                <input readonly type="text" class="form-control" id="bairro" placeholder="Cidade - Estado">
                            </div>
                            <div class="col-md-2 mb-2">
                                <label for="cidadeestado">Cidade - Estado</label>
                                <input readonly type="text" class="form-control" id="cidadeestado" placeholder="Cidade - Estado">
                            </div>
                        </div>
                    </form>
                    
                </div>
                <div v-if="prospect && prospect.responsavel" class="tab-pane fade" id="respedu" role="tabpanel" aria-labelledby="respedu-tab" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <form class="dadosCaptacao">
                        <div class="form-row">
                            <div class="col-md-2 mb-2">
                                <label for="idcrm">ID CRM</label>
                                <input readonly type="text" class="form-control" id="idcrm" placeholder="ID CRM">
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="nomecompleto">Nome</label>
                                <input readonly type="text" class="form-control" id="nomecompleto" placeholder="Nome Completo">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="nomesocial">Nome Social</label>
                                <input readonly type="text" class="form-control" id="nomesocial" placeholder="Nome Social">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="cpf">CPF</label>
                                <the-mask readonly :masked="true" :mask="['###.###.###-##']" class="form-control" id="cpf" name="cpf" placeholder="CPF"/>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="datanascimento">Data de Nascimento</label>
                                <the-mask readonly :masked="true" :mask="['##/##/####']" class="form-control" id="datanascimento" name="datanascimento" placeholder="Data de Nascimento"/>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="genero">Genero</label>
                                <input readonly type="text" class="form-control" id="genero" placeholder="Genero">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="estadocivil">Estado Civil</label>
                                <input readonly type="text" class="form-control" id="estadocivil" placeholder="Estado Civil">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="rg">Rg</label>
                                <input readonly type="text" class="form-control" id="rg" name="rg" placeholder="RG">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="orgao_expedidor">Orgão Expedidor</label>
                                <input readonly type="text" class="form-control" id="orgao_expedidor" name="orgao_expedidor" placeholder="Orgão Expedidor">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="data_expedicao_rg">Data Expedição</label>
                                <input readonly type="text" class="form-control" id="data_expedicao_rg" name="data_expedicao_rg" placeholder="Data Expedição">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="cidade_rg">Cidade - Estado Rg</label>
                                <input readonly type="text" class="form-control" id="cidade_rg" name="cidade_rg" placeholder="Cidade RG" :value="prospect.responsavelfinanceiro.cidade_rg + ' - ' + prospect.responsavelfinanceiro.uf_rg">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4 mb-4">
                                <label for="cidade_nascimento">Cidade Naturalidade</label>
                                <input readonly type="text" class="form-control" id="cidade_nascimento" name="cidade_nascimento" placeholder="Cidade Naturalidade">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="estado_nascimento">Estado Naturalidade</label>
                                <input readonly type="text" class="form-control" id="estado_nascimento" name="estado_nascimento" placeholder="Estado Naturalidade">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="pais_nascimento">País Naturalidade</label>
                                <input readonly type="text" class="form-control" id="pais_nascimento" name="pais_nascimento" placeholder="País Naturalidade">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-6 mb-6">
                                <label for="telefone">Telefone</label>
                                <the-mask readonly :masked="false" :mask="['###########']" class="form-control" id="telefone" name="telefone" placeholder="TELEFONE"/>
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="email">E-mail</label>
                                <input readonly type="text" class="form-control" id="email" placeholder="E-mail">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="cep">CEP</label>
                                <the-mask readonly :masked="true" :mask="['#####-###']" class="form-control" id="cep" name="cep" placeholder="CEP"/>
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="endereco">Endereço</label>
                                <input readonly type="text" class="form-control" id="endereco" placeholder="Endereco">
                            </div>
                            <div class="col-md-1 mb-1">
                                <label for="numero">Número</label>
                                <input readonly type="text" class="form-control" id="numero" placeholder="Nº">
                            </div>
                            <div class="col-md-2 mb-2">
                                <label for="bairro">Bairro</label>
                                <input readonly type="text" class="form-control" id="bairro" placeholder="Cidade - Estado">
                            </div>
                            <div class="col-md-2 mb-2">
                                <label for="cidadeestado">Cidade - Estado</label>
                                <input readonly type="text" class="form-control" id="cidadeestado" placeholder="Cidade - Estado">
                            </div>
                        </div>
                    </form>
                    
                </div>
                <div v-if="prospect && prospect.responsavelfinanceiro" class="tab-pane fade" id="respfin" role="tabpanel" aria-labelledby="respfin-tab" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <form class="dadosCaptacao">
                        <div class="form-row">
                            <div class="col-md-2 mb-2">
                                <label for="idcrm">ID CRM</label>
                                <input readonly type="text" class="form-control" id="idcrm" placeholder="ID CRM">
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="nomecompleto">Nome</label>
                                <input readonly type="text" class="form-control" id="nomecompleto" placeholder="Nome Completo">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="nomesocial">Nome Social</label>
                                <input readonly type="text" class="form-control" id="nomesocial" placeholder="Nome Social">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="cpf">CPF</label>
                                <the-mask readonly :masked="true" :mask="['###.###.###-##']" class="form-control" id="cpf" name="cpf" placeholder="CPF"/>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="datanascimento">Data de Nascimento</label>
                                <the-mask readonly :masked="true" :mask="['##/##/####']" class="form-control" id="datanascimento" name="datanascimento" placeholder="Data de Nascimento"/>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="genero">Genero</label>
                                <input readonly type="text" class="form-control" id="genero" placeholder="Genero">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="estadocivil">Estado Civil</label>
                                <input readonly type="text" class="form-control" id="estadocivil" placeholder="Estado Civil">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="rg">Rg</label>
                                <input readonly type="text" class="form-control" id="rg" name="rg" placeholder="RG">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="orgao_expedidor">Orgão Expedidor</label>
                                <input readonly type="text" class="form-control" id="orgao_expedidor" name="orgao_expedidor" placeholder="Orgão Expedidor">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="data_expedicao_rg">Data Expedição</label>
                                <input readonly type="text" class="form-control" id="data_expedicao_rg" name="data_expedicao_rg" placeholder="Data Expedição">
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="cidade_rg">Cidade - Estado Rg</label>
                                <input readonly type="text" class="form-control" id="cidade_rg" name="cidade_rg" placeholder="Cidade RG" :value="prospect.responsavelfinanceiro.cidade_rg + ' - ' + prospect.responsavelfinanceiro.uf_rg">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4 mb-4">
                                <label for="cidade_nascimento">Cidade Naturalidade</label>
                                <input readonly type="text" class="form-control" id="cidade_nascimento" name="cidade_nascimento" placeholder="Cidade Naturalidade">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="estado_nascimento">Estado Naturalidade</label>
                                <input readonly type="text" class="form-control" id="estado_nascimento" name="estado_nascimento" placeholder="Estado Naturalidade">
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="pais_nascimento">País Naturalidade</label>
                                <input readonly type="text" class="form-control" id="pais_nascimento" name="pais_nascimento" placeholder="País Naturalidade">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-6 mb-6">
                                <label for="telefone">Telefone</label>
                                <the-mask readonly :masked="false" :mask="['###########']" class="form-control" id="telefone" name="telefone" placeholder="TELEFONE"/>
                            </div>
                            <div class="col-md-6 mb-6">
                                <label for="email">E-mail</label>
                                <input readonly type="text" class="form-control" id="email" placeholder="E-mail">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label for="cep">CEP</label>
                                <the-mask readonly :masked="true" :mask="['#####-###']" class="form-control" id="cep" name="cep" placeholder="CEP"/>
                            </div>
                            <div class="col-md-4 mb-4">
                                <label for="endereco">Endereço</label>
                                <input readonly type="text" class="form-control" id="endereco" placeholder="Endereco">
                            </div>
                            <div class="col-md-1 mb-1">
                                <label for="numero">Número</label>
                                <input readonly type="text" class="form-control" id="numero" placeholder="Nº">
                            </div>
                            <div class="col-md-2 mb-2">
                                <label for="bairro">Bairro</label>
                                <input readonly type="text" class="form-control" id="bairro" placeholder="Cidade - Estado">
                            </div>
                            <div class="col-md-2 mb-2">
                                <label for="cidadeestado">Cidade - Estado</label>
                                <input readonly type="text" class="form-control" id="cidadeestado" placeholder="Cidade - Estado">
                            </div>
                        </div>
                    </form>
                    
                </div>
                <div v-if="requisicao_status && requisicao_status.data" class="tab-pane fade" id="statusrequisicao" role="tabpanel" aria-labelledby="statusrequisicao-tab" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <form class="dadosCaptacao">
                        <table class="table table-hover table-sm">
                            <thead>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th class="align-middle" style="text-align: center;width: 70px;">
                                        Requisição
                                    </th>
                                    <th class="align-middle" style="text-align: left;">
                                        Nome
                                    </th>
                                    <th class="align-middle" style="text-align: center;width: 100px;">
                                        Data
                                    </th>
                                    <th class="align-middle" style="text-align: center;width: 25px;">
                                        Etapa
                                    </th>
                                    <th class="align-middle" style="text-align: center;width: 25px;">
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="align-middle" style="text-align: center;">
                                        {{ requisicao_status.data.id }}
                                    </td>
                                    <td class="align-middle">
                                        {{ requisicao_status.data.pessoa.nome }}
                                    </td>
                                    <td class="align-middle" style="text-align: center;">
                                        {{ requisicao_status.data.created_at }}
                                    </td>
                                    <td class="align-middle" style="text-transform: capitalize;text-align: center;font-weight: bold;">
                                        
                                        {{ requisicao_status.data.step }}
                                    </td>
                                    <td class="align-middle" style="text-transform: capitalize;text-align: center;font-weight: bold;">
                                        <div v-if="requisicao_status.data.status=='success'" class="alert alert-success text-center" role="alert" style="margin:0px;padding:5px;" >
                                            SUCESSO
                                        </div>
                                        <div v-else class="alert alert-danger text-center" role="alert" style="margin:0px;padding:5px;">
                                            {{ requisicao_status.data.status }}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="table table-hover table-sm" v-if="requisicao_status.data.logs.length>0">
                            <thead>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th class="align-middle" style="text-align: center;width: 70px;">
                                        ID
                                    </th>
                                    <th class="align-middle" style="text-align: left;">
                                        Mensagem
                                    </th>
                                    <th class="align-middle" style="text-align: center;width: 100px;">
                                        Data
                                    </th>
                                    <th class="align-middle" style="text-align: center;width: 25px;">
                                        Etapa
                                    </th>
                                    <th class="align-middle" style="text-align: center;width: 25px;">
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="log, item in requisicao_status.data.logs" :key="item">
                                    <td class="align-middle" style="text-align: center;">
                                        {{ log.id }}
                                    </td>
                                    <td class="align-middle">
                                        {{ log.mensagem }}
                                    </td>
                                    <td class="align-middle" style="text-align: center;">
                                        {{ log.created_at }}
                                    </td>
                                    <td class="align-middle" style="text-transform: capitalize;text-align: center;font-weight: bold;">
                                        {{ requisicao_status.data.step }}
                                    </td>
                                    <td class="align-middle" style="text-transform: capitalize;text-align: center;font-weight: bold;">
                                        
                                        <div v-if="log.status_id==1" class="alert alert-primary text-center" role="alert" style="margin:0px;padding: 5px;"  >
                                            PENDENTE
                                        </div>
                                        <div v-else-if="log.status_id==2" class="alert alert-success text-center" role="alert" style="margin:0px;padding: 5px;">
                                            SUCESSO
                                        </div>
                                        <div v-else-if="log.status_id==3" class="alert alert-danger text-center" role="alert" style="margin:0px;padding: 5px;">
                                            ERRO
                                        </div>
                                        <div v-else-if="log.status_id==4" class="alert alert-warning  text-center" role="alert" style="margin:0px;padding: 5px;">
                                            AVISO
                                        </div>
                                        <div v-else class="alert alert-danger text-center" role="alert" style="margin:0px;padding: 5px;">
                                            {{ log.status_id }}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </form>
                    
                </div>
                <div v-if="prospect && prospect.web_hooks && prospect.web_hooks.length>0" class="tab-pane fade" id="webhooks" role="tabpanel" aria-labelledby="webhooks-tab" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <table class="table table-hover table-sm">
                        <thead>
                            <tr style="background-color: #1c4d5a;color:white;">
                                <th style="text-align: center;max-width: 120px;width: 120px;">
                                    ID
                                </th>
                                <th style="text-align: center;">
                                    Etapas
                                </th>
                                <th style="text-align: center;width: 45px;">
                                    Sinc.
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Data
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Status
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Ações
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="prospect.web_hooks.length>0">
                            <template v-for="aluno_captado,item in prospect.web_hooks" >
                                <tr v-if="aluno_captado.tipoeventoaviso=='c'" :key="item">
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{ aluno_captado.id }}
                                    </td>
                                    <td id="td-html" class="align-middle" v-html="JSON.parse(aluno_captado.request_body).descricao">
                                        
                                    </td>
                                    <td style="white-space: normal;text-align: center;" class="align-middle">
                                        <b-icon v-if="aluno_captado.processado == 1" icon="check-lg" font-scale="1.3" style="color:green"></b-icon>
                                        <b-icon v-else icon="x-lg" font-scale="1.3" style="color:red"></b-icon>
                                    </td>
                                    <td style="text-align: center;" class="align-middle">
                                        {{ formatDate(aluno_captado.created_at,2) }}
                                    </td>
                                    <td class="align-middle" style="text-align: center;margin:0px;">
                                        <div v-if="aluno_captado.status=='success' && aluno_captado.requisicao_id>0" class="alert alert-success text-center" role="alert" style="margin-bottom: 0px;padding: 5px 10px 5px 10px;" >
                                            <b>{{ aluno_captado.status }}</b>
                                        </div>
                                        <div v-else-if="aluno_captado.requisicao_id>0" class="alert alert-danger text-center" role="alert" style="margin-bottom: 0px;padding: 5px 10px 5px 10px;">
                                            <b>{{ aluno_captado.status }}</b>
                                        </div>
                                        
                                    </td>
                                    <td class="align-middle" style="text-align: center;margin:0px;">
                                        
                                        <a v-if="webHook.id!=aluno_captado.id" :href="'/Access/Unity/Unitie/'+prospect.oferta.unity_id+'/captacao/wh/'+aluno_captado.id" class="btn btn-primary btn-sm"><b-icon icon="eye-fill" font-scale="1.3"></b-icon></a>

                                    </td>
                                </tr>

                            </template>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="5" style="text-align: center;font-weight: bold;">
                                    Não existem dados para exibir
                                </td>
                            </tr>
                        </tbody>
                    </table>
                        
                </div>
            </div>
        </div>
        <div class="div-crud-sistema" style="margin-top:15px;padding-top:0px!important;border-radius: 10px 10px 0px 0px!important;">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <a class="nav-link active" id="documentos-tab" data-toggle="tab" href="#documentos" role="tab" aria-controls="documentos" aria-selected="true">Documentação</a>
                </li>
                <li class="nav-item" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <a class="nav-link" id="parcelas-tab" data-toggle="tab" href="#parcelas" role="tab" aria-controls="parcelas" aria-selected="false">Parcelamento</a>
                </li>
                <li v-if="inscricaoAluno && inscricaoAluno.Contrato" class="nav-item" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <a class="nav-link" id="contrato-tab" data-toggle="tab" href="#contrato" role="tab" aria-controls="contrato" aria-selected="false">Contrato</a>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent" >
                <div class="tab-pane fade show active" id="documentos" role="tabpanel" aria-labelledby="documentos-tab" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <div class="col-md-12 mb-12" style="width:100%!important;padding-left: 0px; padding-right: 0px;">
                        <table v-if="inscricaoAluno && inscricaoAluno.Pessoa.DocumentosEntregues && inscricaoAluno.Pessoa.DocumentosEntregues.length>0" class="table table-hover table-sm" style="width:100%!important;white-space:nowrap;">
                            <thead>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th colspan="7" class="align-middle" style="text-align: center;">Documentos Entregues</th>
                                </tr>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th class="align-middle" style="text-align: center;">Item</th>
                                    <th class="align-middle" style="text-align: center;">Doc. CRM</th>
                                    <th class="align-middle" style="text-align: center;">Arquivo</th>
                                    <th class="align-middle" style="text-align: center;">Doc. ERP</th>
                                    <th class="align-middle" style="text-align: center;">Status</th>
                                    <!--th class="align-middle" style="text-align: center;">Obrigatório</th-->
                                    <th class="align-middle" style="text-align: center;">Upload</th>
                                    <th class="align-middle" style="text-align: center;">Ver</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="doc_entregue,key in inscricaoAluno.Pessoa.DocumentosEntregues" :key="key">
                                    <td class="align-middle" style="text-align: center;">{{ key+1 }}</td>
                                    <td class="align-middle" style="text-align: center;">{{ doc_entregue.descricaodocumentocrm }}</td>
                                    <td class="align-middle" style="text-align: center;">{{ doc_entregue.NomeArquivo }}</td>
                                    <td class="align-middle" :style="'text-align: center;width:200px!important;font-weight:bold;color:'+(getDescricaoTipoDocumento(prospect.tipos_documento, doc_entregue.TipoDocumento)!=false?'blue;':'red;')"> {{ (getDescricaoTipoDocumento(prospect.tipos_documento, doc_entregue.TipoDocumento)!=false ? getDescricaoTipoDocumento(prospect.tipos_documento, doc_entregue.TipoDocumento) : 'NÃO CONFIGURADO') }}
                                        <!-- <select name="tiposDocumento" id="tiposDocumento" disabled class="form-control" v-model="doc_entregue.TipoDocumento" style="width:200px!important;">
                                            <option :value="0">SELECIONE UM DOCUMENTO</option>
                                            <option :value="doc.codigodocumento" v-for="doc, key_doc in prospect.tipos_documento" >{{ doc.descricao }}</option>
                                        </select> -->
                                    </td>
                                    <td class="align-middle" style="text-align: center;">
                                        <b-icon v-if="doc_entregue.TipoDocumento && doc_entregue.TipoDocumento>0" icon="check-lg" font-scale="1.3" style="color:green"></b-icon>
                                        <b-icon v-else icon="x-lg" font-scale="1.3" style="color:red"></b-icon>
                                    </td>
                                    <!--td class="align-middle" style="text-align: center;">
                                        <b-icon v-if="doc_entregue.obrigatorio==1" icon="check-lg" font-scale="1.3" style="color:green"></b-icon>
                                        <b-icon v-else icon="x-lg" font-scale="1.3" style="color:red"></b-icon>
                                    </td-->
                                    <td class="align-middle" style="text-align: center;">
                                        <b-form-checkbox
                                                        :id="'upload_documento'+doc_entregue.TipoDocumento"
                                                        v-model="doc_entregue.upload_documento"
                                                        :name="'upload_documento'+doc_entregue.TipoDocumento"
                                                        :value="1"
                                                        :unchecked-value="0"
                                                        :disabled="true"
                                                        :switch="true"
                                                        
                                                    >
                                                    {{ (doc_entregue.upload_documento==0?'Não':'Sim') }}
                                                    </b-form-checkbox>
                                    </td>
                                    <td class="align-middle" style="text-align: center;">
                                        <a :href="(doc_entregue.IdAnexo!=null ? urlDocs+'/doc/'+doc_entregue.IdAnexo : urlDocs+'/doc/'+convertBase64(doc_entregue.Anexo))" class="btn btn-primary btn-sm" target="_blank"><b-icon icon="eye-fill" font-scale="1.3"></b-icon></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-else-if="prospect!=null && prospect.documentos_entregues && prospect.documentos_entregues.length>0" class="table table-hover table-sm" style="width:100%;white-space:nowrap;">
                            <thead>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th class="align-middle" style="text-align: center;">ID</th>
                                    <th class="align-middle" style="text-align: center;">Descrição</th>
                                    <th class="align-middle" style="text-align: center;">Tipo Documento</th>
                                    <th class="align-middle" style="text-align: center;">Status</th>
                                    <!--th class="align-middle" style="text-align: center;">Obrigatório</th-->
                                    <th class="align-middle" style="text-align: center;">Upload</th>
                                    <th class="align-middle" style="text-align: center;">Ver</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="doc_entregue,key in prospect.documentos_entregues" :key="key">
                                    <td class="align-middle" style="text-align: center;">{{ doc_entregue.id }}</td>
                                    <td class="align-middle" style="text-align: center;">{{ doc_entregue.nome }}</td>
                                    <td class="align-middle" style="text-align: center;">
                                        <select name="tiposDocumento" id="tiposDocumento" class="form-control" v-model="doc_entregue.codigodocumento" 
                                            <option :value="0">SELECIONE UM DOCUMENTO</option>
                                            <option :value="doc.codigodocumento" v-for="doc, key_doc in prospect.tipos_documento" >{{ doc.descricao }}</option>
                                        </select>
                                    </td>
                                    <td class="align-middle" style="text-align: center;">
                                        <b-icon v-if="doc_entregue.existe" icon="check-lg" font-scale="1.3" style="color:green"></b-icon>
                                        <b-icon v-else icon="x-lg" font-scale="1.3" style="color:red"></b-icon>
                                    </td>
                                    <!--td class="align-middle" style="text-align: center;">
                                        <b-icon v-if="doc_entregue.obrigatorio==1" icon="check-lg" font-scale="1.3" style="color:green"></b-icon>
                                        <b-icon v-else icon="x-lg" font-scale="1.3" style="color:red"></b-icon>
                                    </td-->
                                    <td class="align-middle" style="text-align: center;">
                                        <b-form-checkbox
                                                        :id="'upload_documento'+doc_entregue.codigodocumento"
                                                        v-model="doc_entregue.upload_documento"
                                                        :name="'upload_documento'+doc_entregue.codigodocumento"
                                                        :value="1"
                                                        :unchecked-value="0"
                                                        :disabled="false"
                                                        :switch="true"
                                                                                                                    >
                                                    {{ (doc_entregue.upload_documento==0?'Não':'Sim') }}
                                                    </b-form-checkbox>
                                    </td>
                                    <td class="align-middle" style="text-align: center;">
                                        <a :href="doc_entregue.url" class="btn btn-primary btn-sm" target="_blank"><b-icon icon="eye-fill" font-scale="1.3"></b-icon></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-else class="table table-hover table-sm" style="width:100%!important;white-space:nowrap;">
                            <thead>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th colspan="7" class="align-middle" style="text-align: center;">Documentos Entregues</th>
                                </tr>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th class="align-middle" style="text-align: center;">Item</th>
                                    <th class="align-middle" style="text-align: center;">Doc. CRM</th>
                                    <th class="align-middle" style="text-align: center;">Arquivo</th>
                                    <th class="align-middle" style="text-align: center;">Doc. ERP</th>
                                    <th class="align-middle" style="text-align: center;">Status</th>
                                    <!--th class="align-middle" style="text-align: center;">Obrigatório</th-->
                                    <th class="align-middle" style="text-align: center;">Upload</th>
                                    <th class="align-middle" style="text-align: center;">Ver</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="7" class="align-middle" style="text-align: center;">
                                        Aguarde...
                                    </td>
                                </tr>
                            </tbody>
                        </table>                            
                    </div>
                </div>
                <div class="tab-pane fade" id="parcelas" role="tabpanel" aria-labelledby="parcelas-tab" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <div class="col-md-12 mb-12" v-if="inscricaoAluno && inscricaoAluno.parcelamento && inscricaoAluno.parcelamento.length>0" style="width:100%!important;padding-left: 0px; padding-right: 0px;">
                        <table v-if="inscricaoAluno && inscricaoAluno.parcelamento && inscricaoAluno.parcelamento.length>0" class="table table-hover table-sm" style="width:100%;white-space:nowrap;">
                            <thead>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th colspan="9" class="align-middle" style="text-align: center;">Parcelamento</th>
                                </tr>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th class="align-middle" style="text-align: center;">Reg. Processo CRM</th>
                                    <th class="align-middle" style="text-align: center;">Geração</th>
                                    <th class="align-middle" style="text-align: center;">Emissão</th>
                                    <th class="align-middle" style="text-align: center;">Vencimento</th> 
                                    <th class="align-middle" style="text-align: center;">Pagamento</th> 
                                    <th class="align-middle" style="text-align: center;">Crédito</th> 
                                    <th class="align-middle" style="text-align: center;">Competência</th> 
                                    <th class="align-middle" style="text-align: center;">R$ Débito</th> 
                                    <th class="align-middle" style="text-align: center;">R$ Pago</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="parcela,item in inscricaoAluno.parcelamento" >
                                    <tr :key="item">
                                        <td class="align-middle" style="text-align: center;">{{ webHook.registroprocesso }}</td>
                                        <td class="align-middle" style="text-align: center;">{{ formatDate(parcela.DataGeracao,2) }}</td>
                                        <td class="align-middle" style="text-align: center;">{{ formatDate(parcela.DataEmissao,2) }}</td>
                                        <td class="align-middle" style="text-align: center;">{{ formatDate(parcela.DataVencimento,2) }}</td>
                                        <td class="align-middle" style="text-align: center;">{{ formatDate(parcela.DataPagamento,2) }}</td>
                                        <td class="align-middle" style="text-align: center;">{{ formatDate(parcela.DataCredito,2) }}</td>
                                        <td class="align-middle" style="text-align: center;">{{ parcela.Competencia,2 }}</td>
                                        <td class="align-middle" style="text-align: center;">{{ retornaString(parcela.ValorDebito).replace('.',',') }}</td>
                                        <td class="align-middle" style="text-align: center;">{{ retornaString(parcela.ValorPago).replace('.',',') }} </td>                                            
                                    </tr>
                                    <tr v-if="parcela.Evento && parcela.Evento.length>0">
                                        <td colspan="9">
                                            <table class="table table-hover table-sm" style="width:100%;">
                                            <thead>
                                                <tr style="background-color: #1c4d5a;color:white;">
                                                    <th colspan="9" class="align-middle" style="text-align: center;">Eventos Parcela</th>
                                                </tr>
                                                <tr style="background-color: #1c4d5a;color:white;">
                                                    <th class="align-middle" style="text-align: center;">ID</th>
                                                    <th colspan="5" class="align-middle" style="text-align: center;">Descrição Evento</th>
                                                    <th class="align-middle" style="text-align: center;">R$ Unitário</th> 
                                                    <th class="align-middle" style="text-align: center;">R$ Total</th> 
                                                    <th class="align-middle" style="text-align: center;">Liquidação</th> 
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="evento, item_evento in parcela.Evento" :key="item_evento" v-if="prospect!=null && prospect.codigoevento && prospect.codigoevento==evento.Evento">
                                                    <td class="align-middle" style="text-align: center;">{{ evento.Evento }}</td>
                                                    <td colspan="5" class="align-middle" style="text-align: center;">{{ prospect.descricaoevento }}</td>
                                                    <td class="align-middle" style="text-align: center;">{{ retornaString(evento.ValorUnitario).replace('.',',') }}</td>
                                                    <td class="align-middle" style="text-align: center;">{{ retornaString(evento.ValorTotal).replace('.',',') }}</td>
                                                    <td class="align-middle" style="text-align: center;">
                                                        <b-icon v-if="evento.IndicaLiquidacao" icon="check-lg" font-scale="1.3" style="color:green"></b-icon>
                                                        <b-icon v-else icon="x-lg" font-scale="1.3" style="color:red"></b-icon>
                                                    </td>                                          
                                                </tr>
                                                <tr v-for="evento, item_evento in parcela.Evento" :key="item_evento" v-if="prospect!=null && prospect.codigoeventodesconto &&prospect.codigoeventodesconto==evento.Evento">
                                                    <td class="align-middle" style="text-align: center;">{{ evento.Evento }}</td>
                                                    <td colspan="5" class="align-middle" style="text-align: center;">{{ prospect.descricaoeventodesconto }}</td>
                                                    <td class="align-middle" style="text-align: center;">{{ retornaString(evento.ValorUnitario).replace('.',',') }}</td>
                                                    <td class="align-middle" style="text-align: center;">{{ retornaString(evento.ValorTotal).replace('.',',') }}</td>
                                                    <td class="align-middle" style="text-align: center;">
                                                        <b-icon v-if="evento.IndicaLiquidacao" icon="check-lg" font-scale="1.3" style="color:green"></b-icon>
                                                        <b-icon v-else icon="x-lg" font-scale="1.3" style="color:red"></b-icon>
                                                    </td>                                         
                                                </tr>
                                                <tr v-for="evento, item_evento in parcela.Evento" :key="item_evento" v-if="prospect!=null && prospect.codigoeventoservico && prospect.codigoeventoservico==evento.Evento">
                                                    <td class="align-middle" style="text-align: center;">{{ evento.Evento }}</td>
                                                    <td colspan="5" class="align-middle" style="text-align: center;">{{ prospect.descricaoeventoservico }}</td>
                                                    <td class="align-middle" style="text-align: center;">{{ retornaString(evento.ValorUnitario).replace('.',',') }}</td>
                                                    <td class="align-middle" style="text-align: center;">{{ retornaString(evento.ValorTotal).replace('.',',') }}</td>
                                                    <td class="align-middle" style="text-align: center;">
                                                        <b-icon v-if="evento.IndicaLiquidacao" icon="check-lg" font-scale="1.3" style="color:green"></b-icon>
                                                        <b-icon v-else icon="x-lg" font-scale="1.3" style="color:red"></b-icon>
                                                    </td>   
                                                </tr>

                                            </tbody>
                                        </table>
                                        </td>
                                    </tr>
                                </template>

                            </tbody>
                        </table>
                        <table v-else class="table table-hover table-sm" style="width:100%;white-space:nowrap;">
                            <thead>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th colspan="9" class="align-middle" style="text-align: center;">Parcelamento</th>
                                </tr>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th class="align-middle" style="text-align: center;">Reg. Processo CRM</th>
                                    <th class="align-middle" style="text-align: center;">Geração</th>
                                    <th class="align-middle" style="text-align: center;">Emissão</th>
                                    <th class="align-middle" style="text-align: center;">Vencimento</th> 
                                    <th class="align-middle" style="text-align: center;">Pagamento</th> 
                                    <th class="align-middle" style="text-align: center;">Crédito</th> 
                                    <th class="align-middle" style="text-align: center;">Competência</th> 
                                    <th class="align-middle" style="text-align: center;">R$ Débito</th> 
                                    <th class="align-middle" style="text-align: center;">R$ Pago</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="9" class="align-middle" style="text-align: center;">Não identificamos parcelamentos</td>                                            
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-12 mb-12" v-else style="width:100%!important;padding-left: 0px; padding-right: 0px;">
                        <table class="table table-hover table-sm" style="width:100%;white-space:nowrap;">
                            <thead>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th colspan="9" class="align-middle" style="text-align: center;">Parcelamento</th>
                                </tr>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th class="align-middle" style="text-align: center;">Reg. Processo CRM</th>
                                    <th class="align-middle" style="text-align: center;">Geração</th>
                                    <th class="align-middle" style="text-align: center;">Emissão</th>
                                    <th class="align-middle" style="text-align: center;">Vencimento</th> 
                                    <th class="align-middle" style="text-align: center;">Pagamento</th> 
                                    <th class="align-middle" style="text-align: center;">Crédito</th> 
                                    <th class="align-middle" style="text-align: center;">Competência</th> 
                                    <th class="align-middle" style="text-align: center;">R$ Débito</th> 
                                    <th class="align-middle" style="text-align: center;">R$ Pago</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="9" class="align-middle" style="text-align: center;">Não identificamos parcelamentos</td>                                            
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="tab-pane fade" id="contrato" role="tabpanel" aria-labelledby="contrato-tab" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <div class="col-md-12 mb-12" v-if="inscricaoAluno && inscricaoAluno.Contrato" style="width:100%!important;padding-left: 0px; padding-right: 0px;">
                        <table v-if="inscricaoAluno && inscricaoAluno.Contrato" class="table table-hover table-sm" style="width:100%;white-space:nowrap;">
                            <thead>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th colspan="5" class="align-middle" style="text-align: center;">Contrato</th>
                                </tr>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th class="align-middle" style="text-align: center;">Numero</th>
                                    <th class="align-middle" style="text-align: center;">URL Contrato</th>
                                    <th class="align-middle" style="text-align: center;">Aceite</th>
                                    <th class="align-middle" style="text-align: center;">Assinado</th>
                                    <th class="align-middle" style="text-align: center;">Ver</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template>
                                    <tr>
                                        <td class="align-middle" style="text-align: center;">{{ inscricaoAluno.Contrato.Numero }}</td>
                                        <td class="align-middle" style="text-align: center;">{{ inscricaoAluno.Contrato.ArquivoContrato }}</td>
                                        <td class="align-middle" style="text-align: center;">{{ inscricaoAluno.Contrato.DataAceite }}</td>
                                        <td class="align-middle" style="text-align: center;">{{ inscricaoAluno.Contrato.AssinouContrato }}</td>
                                        <td class="align-middle" style="text-align: center;">
                                            <a :href="(inscricaoAluno.Contrato.IdAnexo!=null ? urlDocs+'/doc/'+ inscricaoAluno.Contrato.IdAnexo : urlDocs+'/doc/'+convertBase64(inscricaoAluno.Contrato.ArquivoContrato))" class="btn btn-primary btn-sm" target="_blank"><b-icon icon="eye-fill" font-scale="1.3"></b-icon></a>
                                        </td>
                                    </tr>
                                </template>

                            </tbody>
                        </table>
                        <table v-else class="table table-hover table-sm" style="width:100%;white-space:nowrap;">
                            <thead>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th colspan="5" class="align-middle" style="text-align: center;">Contrato</th>
                                </tr>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th class="align-middle" style="text-align: center;">Numero/th>
                                    <th class="align-middle" style="text-align: center;">URL Contrato</th>
                                    <th class="align-middle" style="text-align: center;">Aceite</th>
                                    <th class="align-middle" style="text-align: center;">Assinado</th>
                                    <th class="align-middle" style="text-align: center;">Ver</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="5" class="align-middle" style="text-align: center;">Não identificamos nenhum contrato</td>                                            
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-12 mb-12" v-else style="width:100%!important;padding-left: 0px; padding-right: 0px;">
                        <table class="table table-hover table-sm" style="width:100%;white-space:nowrap;">
                            <thead>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th colspan="9" class="align-middle" style="text-align: center;">Parcelamento</th>
                                </tr>
                                <tr style="background-color: #1c4d5a;color:white;">
                                    <th class="align-middle" style="text-align: center;">Reg. Processo CRM</th>
                                    <th class="align-middle" style="text-align: center;">Geração</th>
                                    <th class="align-middle" style="text-align: center;">Emissão</th>
                                    <th class="align-middle" style="text-align: center;">Vencimento</th> 
                                    <th class="align-middle" style="text-align: center;">Pagamento</th> 
                                    <th class="align-middle" style="text-align: center;">Crédito</th> 
                                    <th class="align-middle" style="text-align: center;">Competência</th> 
                                    <th class="align-middle" style="text-align: center;">R$ Débito</th> 
                                    <th class="align-middle" style="text-align: center;">R$ Pago</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="9" class="align-middle" style="text-align: center;">Não identificamos parcelamentos</td>                                            
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <loading id="sincronizarEnvioInscricao" titulo="Enviando Inscrição para o ERP, Aguarde..." :sem-padding="true" gif-carregando="/imagens/carregando.gif"></loading>
        <loading id="GetDadosInscrito" titulo="Obtendo dados dos contatos no CRM, Aguarde..." :sem-padding="true" gif-carregando="/imagens/carregando.gif"></loading>
        <loading id="EnviarValorMatricula" titulo="EnviarValorMatricula no CRM, Aguarde..." :sem-padding="true" gif-carregando="/imagens/carregando.gif"></loading>
        <loading id="EnviarAtualizacaoMatricula" titulo="Enviando Atualização Cadastral para o ERP, Aguarde..." :sem-padding="true" gif-carregando="/imagens/carregando.gif"></loading>
        <!--<pre style="background-color:black;color:white;padding-left:10%;"><br>prospect responsavel: <br>{{ (prospect ? prospect : '') }}</pre>
        <pre v-if="inscricaoAluno.Pessoa" style="background-color:black;color:white;padding-left:10%;"><br>inscricaoAluno:<br>{{ inscricaoAluno }}</pre>-->
    </div>
    
</template>

<script>
export default {
    props:[
        // 'dadosCaptacao',
        'webHook',
        'unidade',
        'permissaoUsuario',
        'oferta',
        'objetoInscricao',
        'insereAtualiza',
        'objetoParcelamento',
        'objetoAtualizacaoCadastral'
        // 'listaEstadoCivil',
        // 'listaEtinia',
        // 'configuracaoFinanceira',
        // 'statusRequisicao'
    ],
    data(){
        return{
            urlDocs:window.location.href,
            prospect:null,
            requestWebhook:null,
            requisicao_status:null,
            pagamento_taxa_inscricao:null,
            pagamento_taxa_matricula:null,
            inscricaoAluno:null,
            CriticaInscricaoAluno:null,
            ParcelamentoMatricula:null,
            AtualizacaoCadastralMatricula:null,
            listaEstadoCivil:null,
            listaEtinia:null,
            configuracaoFinanceira:null,
            statusRequisicao:null,
            erro:null
        }
    },
    methods:{
        convertBase64(text){
            return window.btoa(encodeURI(text))
        },
        isMail(email){
            let myArray = email.split("");

            let result = myArray.filter( (letra) => (letra == "@" ? true : false) );

            if(result.length>0 && result[0]=="@"){
                
                return true
            }else{
                
                return false
            }

        },
        retornaString( valor ){
            return ""+valor
        },
        formatPrice(value) {
            
            let aaa = ""+parseFloat(value).toFixed(2)
            console.log('aaa',aaa)
            //let val = Number(aaa.replace(".", ","));
            //if (!val) return '0,00';
            let valueString = aaa.replace(".", ",");
            return valueString.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        getDescricaoTipoDocumento(listaDocumentos, tipodocumento){
            
            for(let i = 0; i < listaDocumentos.length;i++){
                
                if(listaDocumentos[i].codigodocumento==tipodocumento){

                    // console.log(listaDocumentos[i].descricao,tipodocumento)
                    return listaDocumentos[i].descricao
                }

            }

            return false;
        },
        getDadosInscricaoAluno(){
            this.erro = null;
            $('#GetDadosInscrito').modal('show');
            let url = '/api/v1/Crm/getDadosInscricaoAluno/'+this.unidade.id+'/captacao/wh/'+this.webHook.id

            console.log('URL: ' + url)
            var config = {
                method: 'get',
                url: url,
                // headers: {
                //         'Accept': 'application/json',
                //         'Authorization': this.$root.token(),
                //     },
                };
                //executa requisição
                axios(config)
                .then(response => {
                    console.log('response.data',response.data)
                    if(this.listaEstadoCivil = response.data.dadosInscricao && this.listaEstadoCivil == response.data.dadosInscricao.erro==false){
                        
                        if(response.data.dadosInscricao.InformacoesWebHook){

                            this.inscricaoAluno = response.data.dadosInscricao.InformacoesWebHook
                            this.CriticaInscricaoAluno = response.data.critica
                            console.log("this.critica.",response.data.critica)
                            this.ParcelamentoMatricula.Parcelamento = (this.inscricaoAluno && this.inscricaoAluno.parcelamento ? this.inscricaoAluno.parcelamento : 0)
                            if(this.AtualizacaoCadastralMatricula && this.AtualizacaoCadastralMatricula.Pessoa){

                                this.AtualizacaoCadastralMatricula.Pessoa = this.inscricaoAluno.Pessoa
                            }

                            if(this.AtualizacaoCadastralMatricula && this.AtualizacaoCadastralMatricula.Pai){
                                this.AtualizacaoCadastralMatricula.Pai = this.inscricaoAluno.Pai
                            }

                            if(this.AtualizacaoCadastralMatricula && this.AtualizacaoCadastralMatricula.Mae){
                                this.AtualizacaoCadastralMatricula.Mae = this.inscricaoAluno.Mae
                            }
                            console.log("this.inscricaoAluno",this.inscricaoAluno)

                        }

                        if(response.data.dadosInscricao.lista_ec){

                            this.listaEstadoCivil = response.data.dadosInscricao.lista_ec;

                        }

                        if(response.data.dadosInscricao.lista_etnias){

                            this.listaEtinia = response.data.dadosInscricao.lista_etnias;

                        }
                        if(response.data.dadosInscricao.configuracao_financeira_inscricao){
                            
                            this.configuracaoFinanceira =response.data.dadosInscricao.configuracao_financeira_inscricao;

                        }

                        if(response.data.dadosInscricao.request_status_inscricao){

                            this.requisicao_status = response.data.dadosInscricao.request_status_inscricao;

                        }

                        if(response.data.dadosInscricao.dadosContato){
                            
                            this.prospect = response.data.dadosInscricao.dadosContato;
                            this.prospect.oferta.valoroferta = this.formatPrice(this.prospect.oferta.valoroferta)

                        }

                        
    
                        if(this.prospect){

                            if(this.prospect.inscrito.datanascimento){
        
                                this.prospect.inscrito.datanascimento = this.formatDate(this.prospect.inscrito.datanascimento + " 00:00:00",2)
        
                            }else{
                                this.prospect.inscrito.datanascimento = null
                            }

                            if(this.prospect.inscrito.data_expedicao_rg){
        
                                this.prospect.inscrito.data_expedicao_rg = this.formatDate(this.prospect.inscrito.data_expedicao_rg + " 00:00:00",2)
        
                            }else{
                                this.prospect.inscrito.data_expedicao_rg = null
                            }

                        }
    
                        if(this.prospect){

                            if(this.prospect.pai && this.prospect.pai.datanascimento){
        
                                this.prospect.pai.datanascimento = this.formatDate(this.prospect.pai.datanascimento + " 00:00:00",2)
        
                            }
        
                            if(this.prospect.pai && this.prospect.pai.data_expedicao_rg){
        
                                this.prospect.pai.data_expedicao_rg = this.formatDate(this.prospect.pai.data_expedicao_rg + " 00:00:00",2)
        
                            }

                        }
    
                        if(this.prospect){

                            if(this.prospect.mae && this.prospect.mae.datanascimento){
        
                                this.prospect.mae.datanascimento = this.formatDate(this.prospect.mae.datanascimento + " 00:00:00",2)
        
                            }
        
                            if(this.prospect.mae && this.prospect.mae.data_expedicao_rg){
        
                                this.prospect.mae.data_expedicao_rg = this.formatDate(this.prospect.mae.data_expedicao_rg + " 00:00:00",2)
        
                            }

                        }
                        
                        if(this.prospect){

                            if(this.prospect.responsavelfinanceiro && this.prospect.responsavelfinanceiro.datanascimento){
        
                                this.prospect.responsavelfinanceiro.datanascimento = this.formatDate(this.prospect.responsavelfinanceiro.datanascimento + " 00:00:00",2)
        
                            }

                            if(this.prospect.responsavelfinanceiro && this.prospect.responsavelfinanceiro.data_expedicao_rg){
        
                                this.prospect.responsavelfinanceiro.data_expedicao_rg = this.formatDate(this.prospect.responsavelfinanceiro.data_expedicao_rg + " 00:00:00",2)
        
                            }
                        }

                        if(!this.prospect){
                            this.erro = response.data
                            
                            if(this.erro.dadosInscricao.erro==true){

                                //window.alert(this.erro.dadosInscricao.message)
                                console.log('mensagem',this.erro);

                            }
                        }
                        console.log('this.prospect',this.prospect)
                        // this.documentosEntreguesAluno()
                    }else{
                        window.alert('houve um erro')
                        this.mensagem = response.data
                        console.log('mensagem',this.mensagem);
                        
                    }
                    $('#GetDadosInscrito').modal('hide');
                })
                .catch(function (error) {
                    console.log('error',error);
                    window.alert('houve um erro no processamento da inscrição')
                    $('#GetDadosInscrito').modal('hide');
                    //this.erro = error
                    console.log(error);
                });
                
                
        },
        enviarInscricaoProcessamento(){
            this.erro = null;
            $('#sincronizarEnvioInscricao').modal('show');
            let url='/api/v1/Erp/Inscricoes/'+this.unidade.id+'/inscreveraluno';
            
            let formData = new FormData();
            console.log('this.prospect.objeto_inscricao 2',this.inscricaoAluno)
            formData.append('inscricao', JSON.stringify(this.inscricaoAluno))
            formData.append('webhook', JSON.stringify(this.webHook))
            
            
            let configuracao = {
                // method:'post',
                // headers:{
                //     // // 'Accept': 'application/json',
                //     // // 'Authorization': this.$root.token(),
                // }
            }
            
            axios.post(url,formData,configuracao)
                .then(response => {
                    
                    if(response.data.web_hook){
                        
                        this.requestWebhook = response.data.web_hook;

                    }
                    if(response.data.request_status_inscricao){

                        this.requisicao_status = response.data.request_status_inscricao;

                    }

                    if(response.data.erro && response.data.erro == true){
                        this.erro = response.data;
                    }
                    console.log(response.data)
                    $('#sincronizarEnvioInscricao').modal('hide');
                })
                .catch(errors => {
                    console.log(errors.response)
                    $('#sincronizarEnvioInscricao').modal('hide');
                    window.alert("Houve um erro ao enviar a inscrição para o ERP")
                    
                })
        
        },
        atualizarCadastroERP(){
                        
            this.erro = null;
            $('#EnviarAtualizacaoMatricula').modal('show');
            let url='/api/v1/Erp/Inscricoes/'+this.unidade.id+'/atualizacao_cadastral_matricula';
            
            let formData = new FormData();
            console.log('atualizacao_cadastral',this.AtualizacaoCadastralMatricula)
            formData.append('atualizacao_cadastral', JSON.stringify(this.AtualizacaoCadastralMatricula))
            formData.append('webhook', JSON.stringify(this.webHook))
            
            
            let configuracao = {
                // method:'post',
                // headers:{
                //     // // 'Accept': 'application/json',
                //     // // 'Authorization': this.$root.token(),
                // }
            }
            
            axios.post(url,formData,configuracao)
                .then(response => {
                    
                    if(response.data.web_hook){
                        
                        this.requestWebhook = response.data.web_hook;

                    }
                    if(response.data.obj_atualizacao_cadastral){

                        this.AtualizacaoCadastralMatricula = response.data.obj_atualizacao_cadastral;

                    }

                    if(response.data.erro && response.data.erro == true){
                        this.erro = response.data;
                    }
                    console.log(response.data)
                    $('#EnviarAtualizacaoMatricula').modal('hide');
                })
                .catch(errors => {
                    console.log(errors.response)
                    $('#EnviarAtualizacaoMatricula').modal('hide');
                    window.alert("Houve um erro ao enviar a Atualização Cadastral para o ERP")
                    
                })
        
        
        
        },
        confirmarMatriculaErp(){
            this.erro = null;
            $('#sincronizarEnvioInscricao').modal('show');
            let url='/api/v1/Erp/Inscricoes/'+this.unidade.id+'/incluirparcelamatricula';
            
            let formData = new FormData();
            console.log('parcelamento',this.ParcelamentoMatricula)
            formData.append('parcelamento', JSON.stringify(this.ParcelamentoMatricula))
            formData.append('webhook', JSON.stringify(this.webHook))
            
            
            let configuracao = {
                // method:'post',
                // headers:{
                //     // // 'Accept': 'application/json',
                //     // // 'Authorization': this.$root.token(),
                // }
            }
            
            axios.post(url,formData,configuracao)
                .then(response => {
                    
                    if(response.data.web_hook){
                        
                        this.requestWebhook = response.data.web_hook;

                    }
                    if(response.data.request_status_inscricao){

                        this.requisicao_status = response.data.request_status_inscricao;

                    }

                    if(response.data.erro && response.data.erro == true){
                        this.erro = response.data;
                    }
                    console.log(response.data)
                    $('#sincronizarEnvioInscricao').modal('hide');
                })
                .catch(errors => {
                    console.log(errors.response)
                    $('#sincronizarEnvioInscricao').modal('hide');
                    window.alert("Houve um erro ao enviar a inscrição para o ERP")
                    
                })
        
        
        },
        enviarContratoErp(){
            this.erro = null;
            $('#sincronizarEnvioInscricao').modal('show');
            let url='/api/v1/Erp/Inscricoes/'+this.unidade.id+'/incluircontratomatricula';
            
            let formData = new FormData();
            console.log('contrato',this.inscricaoAluno)
            formData.append('contrato', JSON.stringify(this.inscricaoAluno))
            formData.append('webhook', JSON.stringify(this.webHook))
            
            
            let configuracao = {
                // method:'post',
                // headers:{
                //     // // 'Accept': 'application/json',
                //     // // 'Authorization': this.$root.token(),
                // }
            }
            
            axios.post(url,formData,configuracao)
                .then(response => {
                    
                    if(response.data.web_hook){
                        
                        this.requestWebhook = response.data.web_hook;

                    }
                    
                    if(response.data.erro && response.data.erro == true){
                        this.erro = response.data;
                    }
                    console.log(response.data)
                    $('#sincronizarEnvioInscricao').modal('hide');
                })
                .catch(errors => {
                    console.log(errors.response)
                    $('#sincronizarEnvioInscricao').modal('hide');
                    window.alert("Houve um erro ao enviar a inscrição para o ERP")
                    
                })
        },
        dispararEventoValorMatricula(){
            this.erro = null;
            $('#EnviarValorMatricula').modal('show');
            let url = '/api/v1/Crm/dispararEventoValorMatricula/'+this.unidade.id+'/webhook/'+this.webHook.id
            console.log('url',url)
            console.log('URL: ' + url)
            var config = {
                method: 'get',
                url: url,
                // headers: {
                //         'Accept': 'application/json',
                //         'Authorization': this.$root.token(),
                //     },
                };
                //executa requisição
                axios(config)
                .then(response => {
                    console.log('EnviarValorMatricula',response.data)

                    if(response.data.erro==false){
                        this.requestWebhook = response.data.web_hook
                    }else{
                        console.log(response)
                        window.alert(response.data.mensagem);
                    }
                    
                    $('#EnviarValorMatricula').modal('hide');
                })
                .catch(function (error) {
                    console.log('error',error);
                    window.alert('Houve um erro ao enviar o valor da Matrícula para o CRM. Contate o Administrador do EduConnect CRM!')
                    
                    $('#EnviarValorMatricula').modal('hide');
                    //this.erro = error
                    console.log(error);
                });
                
        },
        documentosEntreguesAlunoPostados(){
            let docs_entregues = []
            if(this.inscricaoAluno.Pessoa.DocumentosEntregues.length>0){

                
                for(let i = 0; i < this.inscricaoAluno.Pessoa.DocumentosEntregues; i++){
                    
                    docs_entregues.push(
                        {
                            TipoDocumento: this.inscricaoAluno.Pessoa.DocumentosEntregues[i].TipoDocumento,
                            Validado: 1,
                            Anexo: this.inscricaoAluno.Pessoa.DocumentosEntregues[i].url,
                            NomeArquivo: this.inscricaoAluno.Pessoa.DocumentosEntregues[i].NomeArquivo,
                            IdAnexo: this.inscricaoAluno.Pessoa.DocumentosEntregues[i].IdAnexo,
                            upload_documento:this.inscricaoAluno.Pessoa.DocumentosEntregues[i].upload_documento,
                          }
                    )

                    // if(this.inscricaoAluno.Pessoa.DocumentosEntregues[i].TipoDocumento>0){

                    //     this.prospect.documentos_entregues[i].existe = true

                    // }else{

                    //     this.prospect.documentos_entregues[i].existe = false

                    // }

                }
            }
            this.inscricaoAluno.Pessoa.DocumentosEntregues = docs_entregues;
            console.log('docs_entregues',docs_entregues)
        },
        documentosEntreguesAluno(tipoDocumento){

            let docs_entregues = []
            if(this.prospect.documentos_entregues.length>0){

                
                for(let i = 0; i < this.prospect.documentos_entregues.length; i++){
                    
                    docs_entregues.push(
                        {
                            TipoDocumento: tipoDocumento,
                            Validado: 1,
                            Anexo: this.prospect.documentos_entregues[i].url,
                            NomeArquivo: this.prospect.documentos_entregues[i].nome,
                            IdAnexo: null,
                            upload_documento:this.prospect.documentos_entregues[i].upload_documento,
                            descricaodocumentocrm:this.prospect.documentos_entregues[i].descricaodocumentocrm
                          }
                    )

                    if(this.prospect.documentos_entregues[i].codigodocumento>0){

                        this.prospect.documentos_entregues[i].existe = true

                    }else{

                        this.prospect.documentos_entregues[i].existe = false

                    }

                }
            }
            this.inscricaoAluno.Pessoa.DocumentosEntregues = docs_entregues;
            console.log('docs_entregues',docs_entregues)
            
        },
        converteJsonObjeto(dados){
            //var x = JSON.stringify(dados.substring(1,dados.length-1),null,'\t');
            var a = JSON.parse(dados.substring(1,dados.length-1))
            //console.log(a)
            return a
        },
        getDateToDay(){
            var d = new Date()
            //console.log('Data Atual',d);
            return d
        },
        formatDate(date,idioma) {
            // console.log(date,idioma)
            var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + (d.getDate()),
            year = d.getFullYear();
            
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            if(idioma==1){
                let dt = [year, month, day].join('-');
                if(dt=='1969-12-31'){
                    return null
                }else{
                    return dt;
                }
            }else if(idioma==2){
                let dt = [day, month, year].join('/');
                if(dt=='31/12/1969'){
                    return null
                }else{
                    return dt;
                }
            }else{
                let dt = [year, month, day].join('-');
                if(dt=='1969-12-31'){
                    return null
                }else{
                    return dt;
                }
            }
        },
        daraHoraBrasileiro(date){
            let dataHora = new Date(date)
            // let dataHoraUtc = new Date()
            return dataHora.toLocaleString('pt-BR', { timezone: 'UTC' })
        },
        getEstiloStatus(status = null || status==''){
            if(status==null){
                return '';
            }else if(status=='success' || status==2){
                return 'alert alert-success text-center'
            }else if (status=='error' || status==3){
                return 'alert alert-danger text-center'
            }else if(status=='pending' || status==1){
                return 'alert alert-primary text-center'
            }else if(status=='warning' || status==4){
                return 'alert alert-warning  text-center'
            }else {
                return 'alert alert-light text-center'
            }
        },
        getEstiloStatusRequisicao(status = null || status==''){
            if(status==null){
                return '';
            }else if(status=='success' || status==2){
                return 'alert alert-success text-center'
            }else if (status=='error' || status==3){
                return 'alert alert-danger text-center'
            }else if(status=='pending' || status==1){
                return 'alert alert-primary text-center'
            }else if(status=='warning' || status==4){
                return 'alert alert-warning  text-center'
            }else {
                return 'alert alert-danger text-center'
            }
        },
        getEstyloDescricaoStatusRequisicao(status = null){
            if(status==null || status==''){
                return 'AGUARDANDO';
            }else if(status=='success' || status==2){
                return 'SUCESSO'
            }else if (status=='error' || status==3){
                return 'ERRO'
            }else if(status=='pending' || status==1){
                return 'PENDENTE'
            }else if(status=='warning' || status==4){
                return 'AVISO'
            }else {
                return ''
            }
        },
        getDescricaoStatusRequisicao(aluno_captado){
            var status = (aluno_captado.tipoeventoaviso=='c' && aluno_captado.status_inscricao!=null ?
                    aluno_captado.status_inscricao : (aluno_captado.tipoeventoaviso=='a' && aluno_captado.status_post_contrato!=null ? 
                        aluno_captado.status_post_contrato  : (aluno_captado.tipoeventoaviso=='a' && aluno_captado.status_inscricao!=null ? 
                            aluno_captado.status_inscricao : (aluno_captado.tipoeventoaviso=='m' && aluno_captado.status_inscricao!=null ? 
                                aluno_captado.status_inscricao : (aluno_captado.tipoeventoaviso=='v' && aluno_captado.status_valormatricula!=null ? 
                                    aluno_captado.status_valormatricula : aluno_captado.status
                                )
                            )
                        )
                    )
                )

            
            if(status==null || status==''){
                return status;
            }else if(status=='success' || status==2){
                return 'SUCESSO'
            }else if (status=='error' || status==3){
                return 'ERRO'
            }else if(status=='pending' || status==1){
                return 'PENDENTE'
            }else if(status=='warning' || status==4){
                return 'AVISO'
            }else {
                return status
            }
        },
        
    },
    mounted(){
        this.ParcelamentoMatricula = this.objetoParcelamento
        this.AtualizacaoCadastralMatricula = this.objetoAtualizacaoCadastral
        this.getDadosInscricaoAluno();
    },
     created(){
        $('#GetDadosInscrito').modal('show');
        
        this.requestWebhook = this.webHook;
        
        //this.inscricaoAluno = this.objetoInscricao
        
    }
}
</script>

<style scoped>
    .div-crud-sistema{
            background: white;
            padding: 0px;
            padding-top: 10px;
            border-radius: 0px 0px 10px 10px;
            
    }

    .nav-item{
        /* width: 16.666%!important; */
        text-align: center;
        border-radius: 10px 10px 10px 10px!important;
    }

    .tab-pane{
        overflow: auto;
        width: 100%;
    }
    .div-crud-sistema > .row > .col-2 > .nav-pills > .nav-link,
    .div-crud-sistema > .nav > .nav-item > .nav-link{
        color:black!important;
        border-radius: 10px 10px 0px 0px!important;
        border: 1px solid #1c4d5a;
        
        height: 100%;
        padding-left: 10px;
    }

    .div-crud-sistema > .row > .col-2 > .nav-pills > .active,
    .div-crud-sistema > .nav > .nav-item > .active{
        color:white!important;
        background-color:#1c4d5a!important;
        border-radius: 10px 10px 0px 0px!important;
        border: 1px solid #1c4d5a;
        
        height: 100%;
        padding-left: 10px;
    }

    .tab-content > .active {
        background-color:#fff!important;
        border: 1px solid #1c4d5a;
    }

    #td-html > p {
        margin:0px!important;
    }

    .nav-tabs {
        border-bottom: 5px solid #1c4d5a;
    }

    .table thead th {
        vertical-align: bottom;
        border: 0px solid #1c4d5a;
    }

    .dadosCaptacao{
        padding: 25px;
    }

    p{
        margin-bottom:0px!important;
    }

    .btn-danger:hover{

        color:white!important;

    }

</style>
