<template>
    <div  class="div-crud-sistema" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
        <div class="row">
            <div class="col col-md-12" style="text-align: center;">
                <h3>{{ titulo }}</h3>
                <h5>Configuração Financeira de Ofertas</h5>
                <button class="btn btn-primary btn-sm" @click.prevent="getListaEventos()">Sincronizar Eventos ERP</button><br>&nbsp;
            </div>
        </div>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" style="background-color: #1c4d5a;color:white;">
                <a class="nav-link active" id="gerais-tab" data-toggle="tab" href="#gerais" role="tab" aria-controls="gerais" aria-selected="true">CONFIGURAÇÕES GERAIS</a>
            </li>
            <li class="nav-item" style="background-color: #1c4d5a;color:white;">
                <a class="nav-link" id="inscricao-tab" data-toggle="tab" href="#inscricao" role="tab" aria-controls="inscricao" aria-selected="true">TAXA DE INSCRIÇÃO</a>
            </li>
            <li class="nav-item" >
                <a class="nav-link" id="matricula-tab" data-toggle="tab" href="#matricula" role="tab" aria-controls="matricula" aria-selected="false">TAXA DE MATRÍCULA</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent" style="width: 100%;">
            <div class="tab-pane fade show active" id="gerais" role="tabpanel" aria-labelledby="gerais-tab" style="width: 100%;">
                <form id="form-inscricao" name="form-inscricao" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <div>
                        <slot name="csrf">

                        </slot>
                    </div>
                    <input type="hidden" name="id_empresa">
                    <div class="row">
                        <div class="col col-md-12" style="padding-right: 0px;margin: 0px;">
                            <table class="table table-hover table-sm" style="border:0px!important;">
                                <thead style="border:0px!important">
                                    <tr style="background-color: #1c4d5a;color:white;">
                                        <th colspan="4" style="text-align: center;" class="align-middle">CONFIGURAÇÕES DE DISPARO DE EVENTO<br>VALOR DA MATRÍCULA</th>
                                    </tr>
                                    <tr style="background-color: #1c4d5a;color:white;">
                                        <th style="text-align: left;width: 30%;" class="align-middle">Lista de Tipos de Evento CRM</th>
                                        <th style="text-align: left;width: 30%;" class="align-middle">Lista Campos Personalizados CRM</th>
                                        <th style="text-align: left;width: 30%;" class="align-middle">Lista Etapas Disparo Aviso</th>
                                        <th style="text-align: center;" class="align-middle">Ações</th>
                                    </tr>
                                </thead>
                                <tbody style="border:0px!important">
                                    <tr>
                                        <td  style="text-align: center;" class="align-middle">
                                            <select  id="tipoeventocrm" name="tipoeventocrm" class="form-control" v-model="tipo_evento_aviso_valor_matricula_selecionado">
                                                <option :value="0">Selecione um Tipo de Parcela e clique em Salvar</option>
                                                
                                                    <option :value="tipo_evento_crm.idcrm" v-for="tipo_evento_crm in configuracao_unidade_inscricao.lista_tipo_evento_crm">{{ tipo_evento_crm.titulo }}</option>
                                                
                                            </select>
                                        </td>
                                        <td style="text-align: center;" class="align-middle">
                                            <select class="form-control" name="camposPersonalizado" id="camposPersonalizado" v-model="custom_field_selecionado">
                                                <option :value="0" selected>SELECIONE UM CAMPO PERSONALIZADOS OBRIGATÒRIO</option>
                                                <optgroup label="CAMPOS PERSONALIZADOS CRM">
                                                    <option :value="campo.id" v-for="campo in configuracao_unidade_inscricao.lista_campos_personalizados">{{ campo.nome }} ({{ campo.coluna }})</option>
                                                </optgroup>
                                            </select>
                                        </td>
                                        <td style="text-align: center;" class="align-middle">
                                            <select class="form-control" name="camposPersonalizado" id="camposPersonalizado" v-model="etapa_selecionada">
                                                <option :value="0" selected>SELECIONE UMA ETAPA DE DISPARO</option>
                                                <optgroup label="ETAPAS DE DISPARO DE AVISO VALOR MATRÍCULA">
                                                    <option :value="'inscricaoconfirmada'" selected>INSCRIÇÃO CONFIRMADA</option>
                                                    <option :value="'contratoaceito'" selected>CONTRATO ACEITO</option>
                                                </optgroup>
                                            </select>
                                        </td>
                                        <td style="text-align: center;" class="align-middle">
                                            <button class="btn btn-primary btn-sm" @click.prevent="salvarConfiguracaoDisparoEventoCRM()">Salvar</button>
                                        </td>
                                    </tr>
                                </tbody>                                    
                            </table>
                        </div>
                    </div>
                </form>
            </div>
            <div class="tab-pane fade" id="inscricao" role="tabpanel" aria-labelledby="inscricao-tab">
                <form id="form-inscricao" name="form-inscricao" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <div>
                        <slot name="csrf">

                        </slot>
                    </div>
                    <input type="hidden" name="id_empresa">
                    <div class="row">
                        <div class="col col-md-12">
                            <table class="table table-hover table-sm" style="border:0px!important">
                                <thead style="border:0px!important">
                                    <tr style="background-color: #1c4d5a;color:white;">
                                        <th colspan="4" style="text-align: center;" class="align-middle">TIPOS DE PARCELA</th>
                                    </tr>
                                    <tr style="background-color: #1c4d5a;color:white;">
                                        <th style="text-align: left;" class="align-middle">Lista de Tipos de Parcela</th>
                                        <th style="text-align: center;" class="align-middle">Situação Parcela Paga</th>
                                        <th style="text-align: left;width: 30%;" class="align-middle">Lista Contas Bancárias ERP</th>
                                        <th style="text-align: center;" class="align-middle">Ações</th>
                                    </tr>
                                </thead>
                                <tbody style="border:0px!important">
                                    <tr>
                                        <td style="text-align: center;" class="align-middle">
                                            <select  id="tipoParcelaInscricao" name="tipoParcelaInscricao" class="form-control" v-model="codigotipoparcela_inscricao_selecionado">
                                                <option :value="0">Selecione um Tipo de Parcela e clique em Salvar</option>
                                                
                                                    <option :value="parcela_tipo_inscricao.CODIGOTIPOPARCELA" v-for="parcela_tipo_inscricao in configuracao_unidade_inscricao.tipos_parcela">{{ parcela_tipo_inscricao.DESCRICAO }}</option>
                                                
                                            </select>
                                        </td>
                                        <td style="text-align: center;" class="align-middle">
                                            <select  id="situacaoParcelaInscricao" name="situacaoParcelaInscricao" class="form-control" v-model="codigosituacaoparcela_inscricao_selecionado">
                                                <option :value="0">Selecione uma Situação de Parcela Paga e clique em Salvar</option>
                                                
                                                    <option :value="situacao_parcela.codigosituacaoparcela" v-for="situacao_parcela in configuracao.inscricao.situacoes_parcela">{{ situacao_parcela.descricao }}</option>
                                                
                                            </select>
                                        </td>
                                        <td style="text-align: center;" class="align-middle">
                                            <select  id="codigobancocontainscricao" name="codigobancocontainscricao" class="form-control" v-model="codigobancoconta_inscricao_selecionado">
                                                <option :value="0">Selecione uma Conta Bancária para Liquidação das Parcelas e clique em Salvar</option>
                                                
                                                    <option :value="conta.codigobancoconta" v-for="conta in configuracao.inscricao.lista_contas">{{ conta.descricaocontabancaria }}</option>
                                                
                                            </select>
                                        </td>
                                        <td style="text-align: center;" class="align-middle">
                                            <button class="btn btn-primary btn-sm" @click.prevent="salvarCodigoTipoParcelaInscricao()">Salvar</button>
                                        </td>
                                    </tr>
                                </tbody>                                    
                            </table>
                            
                            <table class="table table-hover table-sm" style="border:0px!important" v-if="codigotipoparcela_inscricao_selecionado>0">
                                <thead style="border:0px!important">
                                    <tr style="background-color: #1c4d5a;color:white;">
                                        <th colspan="4" style="text-align: center;" class="align-middle">EVENTOS</th>
                                    </tr>
                                    <tr style="background-color: #1c4d5a;color:white;">
                                        <th style="text-align: left;width: 40%;" class="align-middle">Lista de Eventos</th>
                                        <th style="text-align: left;width: 40%;" class="align-middle">Lista de Descontos (Cupom Desconto)</th>
                                        <th style="text-align: left;width: 40%;" class="align-middle">Lista de Eventos de Serviço</th>
                                        <!--th style="text-align: center;" class="align-middle">Valor Evento</th-->
                                        <th style="text-align: center;" class="align-middle">Ações</th>
                                    </tr>
                                </thead>
                                <tbody style="border:0px!important">
                                    <tr v-if="!configuracao_unidade_inscricao.eventos_configurados.length>0">
                                        <td style="text-align: center;" class="align-middle">
                                            <select  id="listaEventos" name="listaEventos" class="form-control" v-model="id_eventoselecionado_inscricao">
                                                <option :value="0">Selecione um evento e clique em adicionar</option>
                                                <optgroup v-for="grupo in configuracao_unidade_inscricao.tipos_evento"  :label="grupo.descricaotipoevento">
                                                    <option v-if="evento.descricaotipoevento==grupo.descricaotipoevento" :value="evento.idevento" v-for="evento in configuracao_unidade_inscricao.lista_eventos">{{ evento.descricaoevento }} - (Id: {{ evento.idevento }})</option>
                                                </optgroup>
                                            </select>
                                        </td>
                                        <td style="text-align: center;" class="align-middle">
                                            <select  id="listaEventosdescontos" name="listaEventosdescontos" class="form-control" v-model="id_evento_desc_selecionado_inscricao">
                                                <option :value="0">Selecione um evento e clique em adicionar</option>
                                                <optgroup v-for="grupo in configuracao_unidade_inscricao.tipos_evento_desc"  :label="grupo.descricaotipoevento">
                                                    <option v-if="evento.descricaotipoevento==grupo.descricaotipoevento" :value="evento.idevento" v-for="evento in configuracao_unidade_inscricao.lista_eventos_desconto">{{ evento.descricaoevento }} - (Id: {{ evento.idevento }})</option>
                                                </optgroup>
                                            </select>
                                        </td>
                                        <td>
                                            <select  id="listaEventosServicos" name="listaEventosServicos" class="form-control" v-model="id_evento_desc_servico_inscricao">
                                                <option :value="0">Selecione um evento e clique em adicionar</option>
                                                <optgroup v-for="grupo in configuracao_unidade_inscricao.tipos_evento_desc"  :label="grupo.descricaotipoevento">
                                                    <option v-if="evento.descricaotipoevento==grupo.descricaotipoevento" :value="evento.idevento" v-for="evento in configuracao_unidade_inscricao.lista_eventos_desconto">{{ evento.descricaoevento }} - (Id: {{ evento.idevento }})</option>
                                                </optgroup>
                                            </select>
                                        </td>
                                        <!--td style="text-align: center;" class="align-middle">
                                            <input type="number" class="form-control" v-model="configuracao_evento_inscricao.valor">
                                        </td-->
                                        <td style="text-align: center;white-space:nowrap;" class="align-middle">
                                            <button class="btn btn-primary btn-sm" @click.prevent="adicionarEventoConfUnidadeInscricao()" v-if="configuracao_evento_inscricao.evento_id>0 /*&& configuracao_evento_inscricao.valor>=0*/">{{ (configuracao_evento_inscricao.evento_id>0 ? 'Salvar Alterações':'Salvar') }}</button>
                                            <button disabled class="btn btn-primary btn-sm" v-else>{{ (configuracao_evento_inscricao.evento_id>0 ? 'Salvar Alterações':'Salvar') }}</button>
                                        </td>
                                    </tr>
                                    <tr v-for="evento in configuracao_unidade_inscricao.eventos_configurados" :key="evento.id">
                                        <td>
                                            <select  id="listaEventos" name="listaEventos" class="form-control" v-model="id_eventoselecionado_inscricao">
                                                <option :value="0">Selecione um evento e clique em adicionar</option>
                                                <optgroup v-for="grupo in configuracao_unidade_inscricao.tipos_evento"  :label="grupo.descricaotipoevento">
                                                    <option v-if="evento.descricaotipoevento==grupo.descricaotipoevento" :value="evento.idevento" v-for="evento in configuracao_unidade_inscricao.lista_eventos">{{ evento.descricaoevento }} - (Id: {{ evento.idevento }})</option>
                                                </optgroup>
                                            </select>
                                        </td>
                                        <td>
                                            <select  id="listaEventosdescontos" name="listaEventosdescontos" class="form-control" v-model="id_evento_desc_selecionado_inscricao">
                                                <option :value="0">Selecione um evento e clique em adicionar</option>
                                                <optgroup v-for="grupo in configuracao_unidade_inscricao.tipos_evento_desc"  :label="grupo.descricaotipoevento">
                                                    <option v-if="evento.descricaotipoevento==grupo.descricaotipoevento" :value="evento.idevento" v-for="evento in configuracao_unidade_inscricao.lista_eventos_desconto">{{ evento.descricaoevento }} - (Id: {{ evento.idevento }})</option>
                                                </optgroup>
                                            </select>
                                        </td>
                                        <td>
                                            <select  id="listaEventosServicos" name="listaEventosServicos" class="form-control" v-model="id_evento_desc_servico_inscricao">
                                                <option :value="0">Selecione um evento e clique em adicionar</option>
                                                <optgroup v-for="grupo in configuracao_unidade_inscricao.tipos_evento_desc"  :label="grupo.descricaotipoevento">
                                                    <option v-if="evento.descricaotipoevento==grupo.descricaotipoevento" :value="evento.idevento" v-for="evento in configuracao_unidade_inscricao.lista_eventos_desconto">{{ evento.descricaoevento }} - (Id: {{ evento.idevento }})</option>
                                                </optgroup>
                                            </select>
                                        </td>
                                        <!--td>
                                            {{ evento.valor }}
                                        </td-->
                                        <td style="text-align: center;white-space:nowrap;" class="align-middle">
                                            <button class="btn btn-primary btn-sm" @click.prevent="adicionarEventoConfUnidadeInscricao()" v-if="configuracao_evento_inscricao.evento_id>0 /*&& configuracao_evento_inscricao.valor>=0*/">{{ (configuracao_evento_inscricao.evento_id>0 ? 'Salvar Alterações':'Salvar') }}</button>
                                            <button disabled class="btn btn-primary btn-sm" v-else>{{ (configuracao_evento_inscricao.evento_id>0 ? 'Salvar Alterações':'Salvar') }}</button>
                                            <button class="btn btn-primary btn-sm" @click.prevent="removerEventoConfUnidadeInscricao(evento)">Remover Evento</button>
                                        </td>
                                        
                                    </tr>
                                </tbody>
                                
                            </table>
                        </div>
                    </div>
                </form>
            </div>
            <div class="tab-pane fade" id="matricula" role="tabpanel" aria-labelledby="matricula-tab">
                <form id="form-matricula" name="form-matricula" style="-webkit-animation: fadein 1s; -moz-animation: fadein 1s; -ms-animation: fadein 1s; -o-animation: fadein 1s; animation: fadein 1s;">
                    <div>
                        <slot name="csrf">

                        </slot>
                    </div>
                    <input type="hidden" name="id_empresa">
                    <div class="row">
                        <div class="col col-md-12">
                            <table class="table table-hover table-sm" style="border:0px!important">
                                <thead style="border:0px!important">
                                    <tr style="background-color: #1c4d5a;color:white;">
                                        <th colspan="4" style="text-align: center;" class="align-middle">TIPOS DE PARCELA</th>
                                    </tr>
                                    <tr style="background-color: #1c4d5a;color:white;">
                                        <th style="text-align: left;" class="align-middle">Lista de Tipos de Parcela</th>
                                        <th style="text-align: left;" class="align-middle">Situação Parcela Paga</th>
                                        <th style="text-align: left;width: 30%;" class="align-middle">Lista Contas Bancárias ERP</th>
                                        <th style="text-align: center;" class="align-middle">Ações</th>
                                    </tr>
                                </thead>
                                <tbody style="border:0px!important">
                                    <tr>
                                        <td style="text-align: center;" class="align-middle">
                                            <select  id="tipoParcelaMatricula" name="tipoParcelaMatricula" class="form-control" v-model="codigotipoparcela_matricula_selecionado">
                                                <option :value="0">Selecione um Tipo de Parcela e clique em Salvar</option>
                                                
                                                    <option :value="parcela_tipo_matricula.CODIGOTIPOPARCELA" v-for="parcela_tipo_matricula in configuracao_unidade_matricula.tipos_parcela">{{ parcela_tipo_matricula.DESCRICAO }}</option>
                                                
                                            </select>
                                        </td>
                                        <td style="text-align: center;" class="align-middle">
                                            <select  id="situacaoParcelaInscricao" name="situacaoParcelaInscricao" class="form-control"  v-model="codigosituacaoparcela_matricula_selecionado">
                                                <option :value="0">Selecione uma Situação de Parcela Paga e clique em Salvar</option>
                                                
                                                    <option :value="situacao_parcela.codigosituacaoparcela" v-for="situacao_parcela in configuracao.matricula.situacoes_parcela">{{ situacao_parcela.descricao }}</option>
                                                
                                            </select>
                                        </td>
                                        <td style="text-align: center;" class="align-middle">
                                            <select  id="codigobancoconta" name="codigobancoconta" class="form-control" v-model="codigobancoconta_matricula_selecionado">
                                                <option :value="0">Selecione uma Conta Bancária para Liquidação das Parcelas e clique em Salvar</option>
                                                
                                                    <option :value="conta.codigobancoconta" v-for="conta in configuracao.matricula.lista_contas">{{ conta.descricaocontabancaria }}</option>
                                                
                                            </select>
                                        </td>
                                        <td style="text-align: center;" class="align-middle">
                                            <button class="btn btn-primary btn-sm" @click.prevent="salvarCodigoTipoParcelaMatricula()">Salvar</button>
                                        </td>
                                    </tr>
                                </tbody>                                    
                            </table>
                            <table class="table table-hover table-sm" style="border:0px!important" v-if="codigotipoparcela_matricula_selecionado>0">
                                <thead style="border:0px!important">
                                    <tr style="background-color: #1c4d5a;color:white;">
                                        <th colspan="5" style="text-align: center;" class="align-middle">EVENTOS<br></th>
                                    </tr>
                                    <tr style="background-color: #1c4d5a;color:white;">
                                        <th style="text-align: left;width: 22%;" class="align-middle">Lista de Eventos</th>
                                        <th style="text-align: left;width: 22%;" class="align-middle">Lista de Descontos (Cupom Desconto)</th>
                                        <th style="text-align: left;width: 22%;" class="align-middle">Lista de Descontos (Geral)</th>
                                        <th style="text-align: left;width: 22%;" class="align-middle">Lista de Eventos de Serviço</th>
                                        <!--th style="text-align: center;" class="align-middle">Valor Evento</th-->
                                        <th style="text-align: center;" class="align-middle">Ações</th>
                                    </tr>
                                </thead>
                                <tbody style="border:0px!important">
                                    <tr v-if="!configuracao_unidade_matricula.eventos_configurados.length>0">
                                        <td style="text-align: center;" class="align-middle">
                                            <select  id="listaEventos" name="listaEventos" class="form-control" v-model="id_eventoselecionado_matricula">
                                                <option :value="0">Selecione um evento e clique em adicionar</option>
                                                <optgroup v-for="grupo in configuracao_unidade_matricula.tipos_evento"  :label="grupo.descricaotipoevento">
                                                    <option v-if="evento.descricaotipoevento==grupo.descricaotipoevento" :value="evento.idevento" v-for="evento in configuracao_unidade_matricula.lista_eventos">{{ evento.descricaoevento }} - (Id: {{ evento.idevento }})</option>
                                                </optgroup>
                                            </select>
                                        </td>
                                        <td style="text-align: center;" class="align-middle">
                                            <select  id="listaEventosdescontos" name="listaEventosdescontos" class="form-control" v-model="id_evento_desc_cupom_selecionado_matricula">
                                                <option :value="0">Selecione um evento e clique em adicionar</option>
                                                <optgroup v-for="grupo in configuracao_unidade_matricula.tipos_evento_desc"  :label="grupo.descricaotipoevento">
                                                    <option v-if="evento.descricaotipoevento==grupo.descricaotipoevento" :value="evento.idevento" v-for="evento in configuracao_unidade_matricula.lista_eventos_desconto">{{ evento.descricaoevento }} - (Id: {{ evento.idevento }})</option>
                                                </optgroup>
                                            </select>
                                        </td>
                                        <td style="text-align: center;" class="align-middle">
                                            <select  id="listaEventosdescontos" name="listaEventosdescontos" class="form-control" v-model="id_evento_desc_geral_selecionado_matricula">
                                                <option :value="0">Selecione um evento e clique em adicionar</option>
                                                <optgroup v-for="grupo in configuracao_unidade_matricula.tipos_evento_desc"  :label="grupo.descricaotipoevento">
                                                    <option v-if="evento.descricaotipoevento==grupo.descricaotipoevento" :value="evento.idevento" v-for="evento in configuracao_unidade_matricula.lista_eventos_desconto">{{ evento.descricaoevento }} - (Id: {{ evento.idevento }})</option>
                                                </optgroup>
                                            </select>
                                        </td>
                                        <td>
                                            <select  id="listaEventosServicos" name="listaEventosServicos" class="form-control" v-model="id_evento_desc_servico_matricula">
                                                <option :value="0">Selecione um evento e clique em adicionar</option>
                                                <optgroup v-for="grupo in configuracao_unidade_matricula.tipos_evento_desc"  :label="grupo.descricaotipoevento">
                                                    <option v-if="evento.descricaotipoevento==grupo.descricaotipoevento" :value="evento.idevento" v-for="evento in configuracao_unidade_matricula.lista_eventos_desconto">{{ evento.descricaoevento }} - (Id: {{ evento.idevento }})</option>
                                                </optgroup>
                                            </select>
                                        </td>
                                        <td style="text-align: center;white-space:nowrap;" class="align-middle">
                                            <button class="btn btn-primary btn-sm" @click.prevent="adicionarEventoConfUnidadeMatricula()" v-if="configuracao_evento_matricula.evento_id>0 && configuracao_evento_matricula.valor>=0">{{ (configuracao_evento_inscricao.evento_id>0 ? 'Salvar Alterações':'Salvar') }}</button>
                                            <button disabled class="btn btn-primary btn-sm" v-else>{{ (configuracao_evento_matricula.evento_id>0 ? 'Salvar Alterações':'Salvar') }}</button>
                                        </td>
                                    </tr>
                                    <tr v-for="evento in configuracao_unidade_matricula.eventos_configurados" :key="evento.id">
                                        <td>
                                            <select  id="listaEventos" name="listaEventos" class="form-control" v-model="id_eventoselecionado_matricula">
                                                <option :value="0">Selecione um evento e clique em adicionar</option>
                                                <optgroup v-for="grupo in configuracao_unidade_matricula.tipos_evento"  :label="grupo.descricaotipoevento">
                                                    <option v-if="evento.descricaotipoevento==grupo.descricaotipoevento" :value="evento.idevento" v-for="evento in configuracao_unidade_matricula.lista_eventos">{{ evento.descricaoevento }} - (Id: {{ evento.idevento }})</option>
                                                </optgroup>
                                            </select>
                                        </td>
                                        <td>
                                            <select  id="listaEventosdescontos" name="listaEventosdescontos" class="form-control" v-model="id_evento_desc_cupom_selecionado_matricula">
                                                <option :value="0">Selecione um evento e clique em adicionar</option>
                                                <optgroup v-for="grupo in configuracao_unidade_matricula.tipos_evento_desc"  :label="grupo.descricaotipoevento">
                                                    <option v-if="evento.descricaotipoevento==grupo.descricaotipoevento" :value="evento.idevento" v-for="evento in configuracao_unidade_matricula.lista_eventos_desconto">{{ evento.descricaoevento }} - (Id: {{ evento.idevento }})</option>
                                                </optgroup>
                                            </select>
                                        </td>
                                        <td>
                                            <select  id="listaEventosdescontos" name="listaEventosdescontos" class="form-control" v-model="id_evento_desc_geral_selecionado_matricula">
                                                <option :value="0">Selecione um evento e clique em adicionar</option>
                                                <optgroup v-for="grupo in configuracao_unidade_matricula.tipos_evento_desc"  :label="grupo.descricaotipoevento">
                                                    <option v-if="evento.descricaotipoevento==grupo.descricaotipoevento" :value="evento.idevento" v-for="evento in configuracao_unidade_matricula.lista_eventos_desconto">{{ evento.descricaoevento }} - (Id: {{ evento.idevento }})</option>
                                                </optgroup>
                                            </select>
                                        </td>
                                        <td>
                                            <select  id="listaEventosServicos" name="listaEventosServicos" class="form-control" v-model="id_evento_desc_servico_matricula">
                                                <option :value="0">Selecione um evento e clique em adicionar</option>
                                                <optgroup v-for="grupo in configuracao_unidade_matricula.tipos_evento_desc"  :label="grupo.descricaotipoevento">
                                                    <option v-if="evento.descricaotipoevento==grupo.descricaotipoevento" :value="evento.idevento" v-for="evento in configuracao_unidade_matricula.lista_eventos_desconto">{{ evento.descricaoevento }} - (Id: {{ evento.idevento }})</option>
                                                </optgroup>
                                            </select>
                                        </td>
                                        <!--td>
                                            {{ evento.valor }}
                                        </td-->
                                        <td style="text-align: center;white-space:nowrap;" class="align-middle">
                                            <button class="btn btn-primary btn-sm" @click.prevent="adicionarEventoConfUnidadeMatricula()" v-if="configuracao_evento_matricula.evento_id>0 && configuracao_evento_matricula.valor>=0">{{ (configuracao_evento_matricula.evento_id>0 ? 'Salvar Alterações':'Salvar') }}</button>
                                            <button disabled class="btn btn-primary btn-sm" v-else>{{ (configuracao_evento_matricula.evento_id>0 ? 'Salvar Alterações':'Salvar') }}</button>
                                            <button class="btn btn-primary btn-sm" @click.prevent="removerEventoConfUnidadeMatricula(evento)">Remover Evento</button>
                                        </td>
                                        
                                    </tr>
                                </tbody>
                                
                            </table>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    
    
        <loading id="sincronizaEventosErp" titulo="Sincronizando eventos com o ERP, Aguarde." :sem-padding="true" gif-carregando="/imagens/carregando.gif"></loading>
        <loading id="salvaConfiguracao" titulo="Salvando configurações" :sem-padding="true" gif-carregando="/imagens/carregando.gif"></loading>
        <!--pre style="background-color:black;color:white;padding-left:10%;"><br>configuracao: <br>{{ configuracao_evento_inscricao }}</pre-->
        
    </div>
</template>

<script>

export default {
    props:['configuracao','unidade','titulo'],
    data(){
        return{
           custom_field_selecionado:null,
           tipo_evento_aviso_valor_matricula_selecionado:0,
           etapa_selecionada:0,
           codigotipoparcela_inscricao_selecionado:0,
           codigosituacaoparcela_inscricao_selecionado:0,
           codigobancoconta_inscricao_selecionado:0,
           codigobancoconta_matricula_selecionado:0,
           configuracao_unidade_inscricao:null,
           id_eventoselecionado_inscricao:0,
           id_evento_desc_selecionado_inscricao:0,
           id_evento_desc_servico_inscricao:0,
           configuracao_evento_inscricao:{
                set_finances_unitie_id:null,
                unity_id:null,
                evento_id:null,
                evento_desconto_id:null,
                evento_servico_id:null,
                valor:0.00
           },
           codigotipoparcela_matricula_selecionado:0,
           codigosituacaoparcela_matricula_selecionado:0,
           configuracao_unidade_matricula:null,
           id_eventoselecionado_matricula:0,
           id_evento_desc_cupom_selecionado_matricula:0,
           id_evento_desc_geral_selecionado_matricula:0,
           
           id_evento_desc_servico_matricula:0,
           configuracao_evento_matricula:{
                set_finances_unitie_id:null,
                unity_id:null,
                evento_id:null,
                evento_desconto_id:null,
                evento_desconto_geral_id:null,
                evento_servico_id:null,
                valor:0.00
           }
        }
    },
    computed:{
        
        
    },
    created(){
        console.log('configuracao',this.configuracao)
        this.configuracao_unidade_inscricao = this.configuracao.inscricao;
        this.configuracao_evento_inscricao.set_finances_unitie_id = this.configuracao_unidade_inscricao.set_finances_unitie_id
        this.configuracao_evento_inscricao.unity_id = this.unidade.id
        this.codigotipoparcela_inscricao_selecionado = this.configuracao.inscricao.codigotipoparcela
        this.custom_field_selecionado = (this.configuracao.inscricao.custom_field ? this.configuracao.inscricao.custom_field : 0)
        this.etapa_selecionada = (this.configuracao.inscricao.etapaenvioeventoaviso ? this.configuracao.inscricao.etapaenvioeventoaviso:0)
        this.tipo_evento_aviso_valor_matricula_selecionado = this.configuracao.inscricao.tipo_evento_aviso_valor_matricula
        this.codigosituacaoparcela_inscricao_selecionado = this.configuracao.inscricao.codigosituacaoparcela
        this.codigobancoconta_inscricao_selecionado = this.configuracao.inscricao.codigobancoconta
        this.codigobancoconta_matricula_selecionado = this.configuracao.matricula.codigobancoconta
        
        console.log('this.codigobancoconta_matricula_selecionado',this.codigobancoconta_matricula_selecionado)
        if(this.configuracao.inscricao.eventos_configurados.length>0){
            if(this.configuracao.inscricao.eventos_configurados[0].event!=null){
                this.id_eventoselecionado_inscricao = this.configuracao.inscricao.eventos_configurados[0].event.idevento
            }else{
                this.id_eventoselecionado_inscricao = 0
            }
        }else{
            this.id_eventoselecionado_inscricao = 0
        }

        if(this.configuracao.inscricao.eventos_configurados.length>0){
            if(this.configuracao.inscricao.eventos_configurados[0].event_discount!=null){
                this.id_evento_desc_selecionado_inscricao = this.configuracao.inscricao.eventos_configurados[0].event_discount.idevento
            }else{
                this.id_evento_desc_selecionado_inscricao = 0
            }
        }else{
            this.id_evento_desc_selecionado_inscricao = 0
        }

        if(this.configuracao.inscricao.eventos_configurados.length>0){
            if(this.configuracao.inscricao.eventos_configurados[0].event_discount!=null){
                this.id_evento_desc_servico_inscricao = this.configuracao.inscricao.eventos_configurados[0].event_service.idevento
            }else{
                this.id_evento_desc_servico_inscricao = 0
            }
        }else{
            this.id_evento_desc_servico_inscricao = 0
        }

        

        

        this.configuracao_unidade_matricula = this.configuracao.matricula;
        this.configuracao_evento_matricula.set_finances_unitie_id = this.configuracao_unidade_matricula.set_finances_unitie_id
        this.configuracao_evento_matricula.unity_id = this.unidade.id
        this.codigotipoparcela_matricula_selecionado = this.configuracao.matricula.codigotipoparcela
        this.codigosituacaoparcela_matricula_selecionado = this.configuracao.matricula.codigosituacaoparcela

        if(this.configuracao.matricula.eventos_configurados.length>0){
            if(this.configuracao.matricula.eventos_configurados[0].event!=null){
                this.id_eventoselecionado_matricula = this.configuracao.matricula.eventos_configurados[0].event.idevento
            }else{
                this.id_eventoselecionado_matricula = 0
            }
        }else{
            this.id_eventoselecionado_matricula = 0
        }

        if(this.configuracao.matricula.eventos_configurados.length>0){
            if(this.configuracao.matricula.eventos_configurados[0].event_discount!=null){
                this.id_evento_desc_cupom_selecionado_matricula = this.configuracao.matricula.eventos_configurados[0].event_discount.idevento
            }else{
                this.id_evento_desc_cupom_selecionado_matricula = 0
            }
        }else{
            this.id_evento_desc_cupom_selecionado_matricula = 0
        }

        if(this.configuracao.matricula.eventos_configurados.length>0){
            if(this.configuracao.matricula.eventos_configurados[0].event_discount_geral!=null){
                this.id_evento_desc_geral_selecionado_matricula = this.configuracao.matricula.eventos_configurados[0].event_discount_geral.idevento
            }else{
                this.id_evento_desc_geral_selecionado_matricula = 0
            }
        }else{
            this.id_evento_desc_geral_selecionado_matricula = 0
        }

        if(this.configuracao.matricula.eventos_configurados.length>0){
            if(this.configuracao.matricula.eventos_configurados[0].event_service!=null){
                this.id_evento_desc_servico_matricula = this.configuracao.matricula.eventos_configurados[0].event_service.idevento
            }else{
                this.id_evento_desc_servico_matricula = 0
            }
        }else{
            this.id_evento_desc_servico_matricula = 0
        }

        
        
    },
    mounted() {
        
    },
    watch:{
        id_evento_desc_selecionado_inscricao(new_id, old_id){
            // if(old_id!=new_id){
                //this.id_evento_desc_selecionado_inscricao = new_id;
                console.log(old_id,new_id);
                
                for(let i=0; i < this.configuracao_unidade_inscricao.lista_eventos_desconto.length; i++){
                    if(new_id==0){
                        this.configuracao_evento_inscricao.evento_desconto_id = 0;
                    } else if(parseInt(this.configuracao_unidade_inscricao.lista_eventos_desconto[i].idevento) == new_id){
                        this.configuracao_evento_inscricao.evento_desconto_id = this.configuracao_unidade_inscricao.lista_eventos_desconto[i].id
                    }
                }
            // }
        },
        id_evento_desc_servico_inscricao(new_id, old_id){
            // if(old_id!=new_id){
                //this.id_evento_desc_selecionado_inscricao = new_id;
                console.log(old_id,new_id);
                
                for(let i=0; i < this.configuracao_unidade_inscricao.lista_eventos_desconto.length; i++){
                    if(new_id==0){
                        this.configuracao_evento_inscricao.evento_servico_id = 0;
                    } else if(parseInt(this.configuracao_unidade_inscricao.lista_eventos_desconto[i].idevento) == new_id){
                        this.configuracao_evento_inscricao.evento_servico_id = this.configuracao_unidade_inscricao.lista_eventos_desconto[i].id
                    }
                }
            // }
        },
        id_evento_desc_servico_matricula(new_id, old_id){
            // if(old_id!=new_id){
                //this.id_evento_desc_selecionado_inscricao = new_id;
                console.log(old_id,new_id);
                
                for(let i=0; i < this.configuracao_unidade_matricula.lista_eventos_desconto.length; i++){
                    if(new_id==0){
                        this.configuracao_evento_matricula.evento_servico_id = 0;
                    } else if(parseInt(this.configuracao_unidade_matricula.lista_eventos_desconto[i].idevento) == new_id){
                        this.configuracao_evento_matricula.evento_servico_id = this.configuracao_unidade_matricula.lista_eventos_desconto[i].id
                    }
                }
            // }
        },
        id_evento_desc_cupom_selecionado_matricula(new_id, old_id){
            // if(old_id!=new_id){
                //this.id_evento_desc_cupom_selecionado_matricula = new_id;
                console.log(old_id,new_id);
                
                for(let i=0; i < this.configuracao_unidade_matricula.lista_eventos_desconto.length; i++){
                    if(new_id==0){
                        this.configuracao_evento_matricula.evento_desconto_id = 0;
                    } else if(parseInt(this.configuracao_unidade_matricula.lista_eventos_desconto[i].idevento) == new_id){
                        this.configuracao_evento_matricula.evento_desconto_id = this.configuracao_unidade_matricula.lista_eventos_desconto[i].id
                    }
                }
            // }
        },
        id_evento_desc_geral_selecionado_matricula(new_id, old_id){
            // if(old_id!=new_id){
                //this.id_evento_desc_geral_selecionado_matricula = new_id;
                console.log(old_id,new_id);
                
                for(let i=0; i < this.configuracao_unidade_matricula.lista_eventos_desconto.length; i++){
                    if(new_id==0){
                        this.configuracao_evento_matricula.evento_desconto_geral_id = 0;
                    } else if(parseInt(this.configuracao_unidade_matricula.lista_eventos_desconto[i].idevento) == new_id){
                        this.configuracao_evento_matricula.evento_desconto_geral_id = this.configuracao_unidade_matricula.lista_eventos_desconto[i].id
                    }
                }
            // }
        },
        id_eventoselecionado_inscricao(new_id, old_id){
            // if(old_id!=new_id){
                //this.id_eventoselecionado_inscricao = new_id;
                console.log(old_id,new_id);
                
                for(let i=0; i < this.configuracao_unidade_inscricao.lista_eventos.length; i++){
                    if(new_id==0){
                        this.configuracao_evento_inscricao.evento_id = 0;
                    } else if(parseInt(this.configuracao_unidade_inscricao.lista_eventos[i].idevento) == new_id){
                        this.configuracao_evento_inscricao.evento_id = this.configuracao_unidade_inscricao.lista_eventos[i].id
                    }
                }
            // }
        },
        id_eventoselecionado_matricula(new_id, old_id){
            // if(old_id!=new_id){
                //this.id_eventoselecionado_inscricao = new_id;
                console.log(old_id,new_id);
                
                for(let i=0; i < this.configuracao_unidade_matricula.lista_eventos.length; i++){
                    if(new_id==0){
                        this.configuracao_evento_matricula.evento_id = 0;
                    } else if(parseInt(this.configuracao_unidade_matricula.lista_eventos[i].idevento) == new_id){
                        this.configuracao_evento_matricula.evento_id = this.configuracao_unidade_matricula.lista_eventos[i].id
                    }
                }
            // }
        },
        codigotipoparcela_inscricao_selecionado(new_id,old_id){
            this.configuracao_unidade_inscricao.codigotipoparcela = new_id
            this.codigotipoparcela_inscricao_selecionado = new_id;
        },
        codigotipoparcela_matricula_selecionado(new_id,old_id){
            this.configuracao_unidade_matricula.codigotipoparcela = new_id
            this.codigotipoparcela_matricula_selecionado = new_id;
        },
        codigosituacaoparcela_inscricao_selecionado(new_id,old_id){
            this.configuracao_unidade_inscricao.codigosituacaoparcela = new_id
            this.codigosituacaoparcela_inscricao_selecionado = new_id;
        },
        codigobancoconta_inscricao_selecionado(new_id,old_id){
            this.configuracao_unidade_inscricao.codigobancoconta = new_id
            this.codigobancoconta_inscricao_selecionado = new_id;
        },
        codigosituacaoparcela_matricula_selecionado(new_id,old_id){
            this.configuracao_unidade_matricula.codigosituacaoparcela = new_id
            this.codigosituacaoparcela_matricula_selecionado = new_id;
        },
        codigobancoconta_matricula_selecionado(new_id,old_id){
            this.configuracao_unidade_matricula.codigobancoconta = new_id
            this.codigobancoconta_matricula_selecionado = new_id;
        },
        custom_field_selecionado(new_id,old_id){
            this.configuracao_unidade_matricula.custom_field = new_id
            this.custom_field_selecionado = new_id;
        },

        tipo_evento_aviso_valor_matricula_selecionado(new_id,old_id){
            this.configuracao_unidade_matricula.tipo_evento_aviso_valor_matricula = new_id
            this.tipo_evento_aviso_valor_matricula_selecionado = new_id;
        },
        etapa_selecionada(new_etapa,old_etapa){
            this.configuracao_unidade_matricula.etapaenvioeventoaviso = new_etapa
            this.etapa_selecionada = new_etapa;
        },
    },
    methods:{
        getListaEventos(){
            $('#sincronizaEventosErp').modal('show');
            let url = window.location.origin + '/api/v1/Erp/ListaEventos/' + this.unidade.id
            console.log(url);

            let configuracao = {
                    // method:'put',
                    // headers:{
                    // //     'Accept': 'application/json',
                    // //     'Authorization': this.$root.token()
                    // },
                }
                
            axios.get(url,configuracao)
                .then(response => {
                    this.configuracao_unidade_inscricao.lista_eventos = response.data.lista_eventos
                    this.configuracao_unidade_inscricao.lista_eventos_desconto = response.data.lista_eventos_desconto
                    this.configuracao_unidade_matricula.lista_eventos = response.data.lista_eventos
                    this.configuracao_unidade_matricula.lista_eventos_desconto = response.data.lista_eventos_desconto
                    // this.configuracao_unidade_inscricao.eventos_configurados = response.data.eventos_configurados
                    
                    $('#sincronizaEventosErp').modal('hide');
                    console.log(response.data)
                    // this.resetarDadosEventosInscricao()
                
                })
                .catch(errors => {
                    $('#sincronizaEventosErp').modal('hide');
                    window.alert("Houve um erro");
                    console.log(errors)
                })

        },
        salvarCodigoTipoParcelaInscricao(){
            
            $('#salvaConfiguracao').modal('show');
            let url = window.location.origin + '/api/v1/Finances/Unity/Unitie/' + this.unidade.id + '/Event/'+this.configuracao_evento_inscricao.set_finances_unitie_id+'/update'

            console.log(url);

            let formData = new FormData();

            formData.append('codigotipoparcela',this.codigotipoparcela_inscricao_selecionado)
            formData.append('codigosituacaoparcela',this.codigosituacaoparcela_inscricao_selecionado)
            formData.append('codigobancoconta',this.codigobancoconta_inscricao_selecionado)

            let configuracao = {
                    // method: 'post',
                    // headers:{
                    // //     'Accept': 'application/json',
                    // //     'Authorization': this.$root.token()
                    // },
                }
                
            axios.post(url, formData,configuracao)
                .then(response => {
                    this.codigotipoparcela_inscricao_selecionado = response.data.configuracao.codigotipoparcela
                    $('#salvaConfiguracao').modal('hide');
                    console.log(response.data)
                
                })
                .catch(errors => {
                    console.log(errors)
                    $('#salvaConfiguracao').modal('hide');
                    window.alert("Houve um erro")
                })
        },
        salvarConfiguracaoDisparoEventoCRM(){
            
            $('#salvaConfiguracao').modal('show');
            let url = window.location.origin + '/api/v1/Finances/Unity/Unitie/' + this.unidade.id + '/Event/'+this.configuracao_evento_inscricao.set_finances_unitie_id+'/update_tipoevento'

            console.log(url);

            let formData = new FormData();

            formData.append('codigotipoevento',this.tipo_evento_aviso_valor_matricula_selecionado)
            formData.append('id_custom_field',this.custom_field_selecionado)
            formData.append('etapa',this.etapa_selecionada)

            let configuracao = {
                    // method: 'post',
                    // headers:{
                    // //     'Accept': 'application/json',
                    // //     'Authorization': this.$root.token()
                    // },
                }
                
            axios.post(url, formData,configuracao)
                .then(response => {
                    this.tipo_evento_aviso_valor_matricula_selecionado = response.data.configuracao.tipoeventoavisovalormatricula
                    this.custom_field_selecionado = response.data.configuracao.custom_field_id
                    $('#salvaConfiguracao').modal('hide');
                    console.log(response.data)
                
                })
                .catch(errors => {
                    console.log(errors)
                    $('#salvaConfiguracao').modal('hide');
                    window.alert("Houve um erro")
                })
        },
        resetarDadosEventosInscricao(){
            this.id_eventoselecionado_inscricao = 0;
            this.configuracao_evento_inscricao.evento_id = null
            this.configuracao_evento_inscricao.valor = 0.00
            
            this.id_evento_desc_selecionado_inscricao = 0;
            this.configuracao_evento_inscricao.evento_desconto_id = null
            this.configuracao_evento_inscricao.evento_servico_id = null
        },
        adicionarEventoConfUnidadeInscricao(){
            $('#salvaConfiguracao').modal('show');
            let url = window.location.origin + '/api/v1/Finances/Unity/Unitie/' + this.unidade.id + '/Event'

            console.log(url);

            let formData = new FormData();
            formData.append('dados_evento_adicionar',JSON.stringify(this.configuracao_evento_inscricao))

            let configuracao = {
                    // method:'put',
                    // headers:{
                    // //     'Accept': 'application/json',
                    // //     'Authorization': this.$root.token()
                    // },
                }
                
            axios.post(url, formData,configuracao)
                .then(response => {
                    this.configuracao_unidade_inscricao.lista_eventos = response.data.lista_eventos
                    this.configuracao_unidade_inscricao.lista_eventos_desconto = response.data.lista_eventos_desconto
                    this.configuracao_unidade_inscricao.eventos_configurados = response.data.eventos_configurados
                    this.id_eventoselecionado_inscricao = response.data.eventos_configurados[0].event.idevento
                    this.id_evento_desc_selecionado_inscricao = (response.data.eventos_configurados[0].event_discount!=null ? response.data.eventos_configurados[0].event_discount.idevento:0)
                    //this.resetarDadosEventosInscricao()
                    $('#salvaConfiguracao').modal('hide');
                
                })
                .catch(errors => {
                    console.log(errors)
                    $('#salvaConfiguracao').modal('hide');
                    window.alert("Houve um erro")
                })
        },

        removerEventoConfUnidadeInscricao(evento){
            $('#salvaConfiguracao').modal('show');
            this.configuracao_evento_inscricao.set_finances_unitie_id = evento.set_finances_unitie_id
            this.configuracao_evento_inscricao.evento_id = evento.event_id
            let url = window.location.origin + '/api/v1/Finances/Unity/Unitie/' + this.unidade.id + '/Event/'+this.configuracao_evento_inscricao.set_finances_unitie_id

            console.log(url);

            let formData = new FormData();
            formData.append('dados_evento_remover',JSON.stringify(this.configuracao_evento_inscricao))

            let configuracao = {
                    // method: 'post',
                    // headers:{
                    // //     'Accept': 'application/json',
                    // //     'Authorization': this.$root.token()
                    // },
                }
                
            axios.post(url, formData,configuracao)
                .then(response => {
                    this.configuracao_unidade_inscricao.lista_eventos = response.data.lista_eventos
                    this.configuracao_unidade_inscricao.lista_eventos_desconto = response.data.lista_eventos_desconto
                    this.configuracao_unidade_inscricao.eventos_configurados = response.data.eventos_configurados
                    //window.alert("Evento Removido com Sucesso da configuração Financeira da Unidade");
                    // console.log(response.data)
                    this.resetarDadosEventosInscricao()
                    $('#salvaConfiguracao').modal('hide');
                
                })
                .catch(errors => {
                    console.log(errors)
                    $('#salvaConfiguracao').modal('hide');
                    window.alert("Houve um erro")
                })
        },
        salvarCodigoTipoParcelaMatricula(){
            $('#salvaConfiguracao').modal('show');
            let url = window.location.origin + '/api/v1/Finances/Unity/Unitie/' + this.unidade.id + '/Event/'+this.configuracao_evento_matricula.set_finances_unitie_id+'/update'

            console.log(url);

            let formData = new FormData();

            formData.append('codigotipoparcela',this.codigotipoparcela_matricula_selecionado)
            formData.append('codigosituacaoparcela',this.codigosituacaoparcela_matricula_selecionado)
            formData.append('codigobancoconta',this.codigobancoconta_matricula_selecionado)

            let configuracao = {
                    // method: 'post',
                    // headers:{
                    // //     'Accept': 'application/json',
                    // //     'Authorization': this.$root.token()
                    // },
                }
                
            axios.post(url, formData,configuracao)
                .then(response => {
                    this.codigotipoparcela_matricula_selecionado = response.data.configuracao.codigotipoparcela
                    // window.alert("Tipo de Parcela para Matrículas Configurado com sucesso");
                    console.log(response.data)
                    $('#salvaConfiguracao').modal('hide');
                
                })
                .catch(errors => {
                    console.log(errors)
                    $('#salvaConfiguracao').modal('hide');
                    window.alert("Houve um erro")
                })
        },
        resetarDadosEventosMatricula(){
            this.id_eventoselecionado_matricula = 0;
            this.configuracao_evento_matricula.evento_id = null
            this.configuracao_evento_matricula.valor = 0.00

            this.id_evento_desc_cupom_selecionado_matricula = 0;
            this.configuracao_evento_matricula.evento_desconto_id = null
            this.configuracao_evento_matricula.evento_servico_id = null

            this.id_evento_desc_geral_selecionado_matricula = 0;
            this.id_evento_desc_servico_matricula = 0;
            this.configuracao_evento_matricula.evento_desconto_geral_id = null
        },
        adicionarEventoConfUnidadeMatricula(){
            $('#salvaConfiguracao').modal('show');
            let url = window.location.origin + '/api/v1/Finances/Unity/Unitie/' + this.unidade.id + '/Event'

            console.log(url);

            let formData = new FormData();
            formData.append('dados_evento_adicionar',JSON.stringify(this.configuracao_evento_matricula))

            let configuracao = {
                    // method:'put',
                    // headers:{
                    // //     'Accept': 'application/json',
                    // //     'Authorization': this.$root.token()
                    // },
                }
                
            axios.post(url, formData,configuracao)
                .then(response => {
                    this.configuracao_unidade_matricula.lista_eventos = response.data.lista_eventos
                    this.configuracao_unidade_matricula.lista_eventos_desconto = response.data.lista_eventos_desconto
                    this.configuracao_unidade_matricula.eventos_configurados = response.data.eventos_configurados
                    this.id_eventoselecionado_matricula = response.data.eventos_configurados[0].event.idevento
                    this.id_evento_desc_cupom_selecionado_matricula = (response.data.eventos_configurados[0].event_discount!=null ? response.data.eventos_configurados[0].event_discount.idevento:0)
                    this.id_evento_desc_geral_selecionado_matricula = (response.data.eventos_configurados[0].event_discount_geral!=null ? response.data.eventos_configurados[0].event_discount_geral.idevento:0)
                    //window.alert("Evento Adicionado com Sucesso na configuração Financeira da Unidade");
                    // console.log(response.data)
                    //this.resetarDadosEventosInscricao()
                    $('#salvaConfiguracao').modal('hide');
                
                })
                .catch(errors => {
                    console.log(errors)
                    $('#salvaConfiguracao').modal('hide');
                    window.alert("Houve um erro")
                })
        },

        removerEventoConfUnidadeMatricula(evento){
            $('#salvaConfiguracao').modal('show');
            this.configuracao_evento_matricula.set_finances_unitie_id = evento.set_finances_unitie_id
            this.configuracao_evento_matricula.evento_id = evento.event_id
            let url = window.location.origin + '/api/v1/Finances/Unity/Unitie/' + this.unidade.id + '/Event/'+this.configuracao_evento_matricula.set_finances_unitie_id

            console.log(url);

            let formData = new FormData();
            formData.append('dados_evento_remover',JSON.stringify(this.configuracao_evento_matricula))

            let configuracao = {
                    // method: 'post',
                    // headers:{
                    // //     'Accept': 'application/json',
                    // //     'Authorization': this.$root.token()
                    // },
                }
                
            axios.post(url, formData,configuracao)
                .then(response => {
                    this.configuracao_unidade_matricula.lista_eventos = response.data.lista_eventos
                    this.configuracao_unidade_matricula.lista_eventos_desconto = response.data.lista_eventos_desconto
                    this.configuracao_unidade_matricula.eventos_configurados = response.data.eventos_configurados
                    
                    // window.alert("Evento Removido com Sucesso da configuração Financeira da Unidade");
                    console.log(response.data)
                    this.resetarDadosEventosMatricula()
                    $('#salvaConfiguracao').modal('hide');
                
                })
                .catch(errors => {
                    console.log(errors)
                    $('#salvaConfiguracao').modal('hide');
                    window.alert("Houve um erro")
                })
        }
        
    }
}
</script>

<style scoped>
    
    .div-crud-sistema{
            background: white;
            padding: 0px;
            padding-top: 10px;
            border-radius: 0px 0px 10px 10px;
            
    }

    .nav-item{
        width: 33.3333%!important;
        text-align: center;
        border-radius: 10px 10px 10px 10px!important;
    }

    .tab-pane{
        overflow: hidden;
        width: 100%;
        
    }
    .div-crud-sistema > .row > .col-2 > .nav-pills > .nav-link,
    .div-crud-sistema > .nav > .nav-item > .nav-link{
        color:black!important;
        background-color:#fff!important;
        border-radius: 10px 10px 0px 0px!important;
        border: 1px solid #1c4d5a;
        
        height: 100%;
        padding-left: 10px;
    }

    .div-crud-sistema > .row > .col-2 > .nav-pills > .active,
    .div-crud-sistema > .nav > .nav-item > .active{
        color:white!important;
        background-color:#1c4d5a!important;
        border-radius: 10px 10px 0px 0px!important;
        border: 1px solid #1c4d5a;
        
        height: 100%;
        padding-left: 10px;
    }

    .tab-content > .active {
        background-color:#fff!important;
        border: 1px solid #1c4d5a;
    }

    #td-html > p {
        margin:0px!important;
    }

    .nav-tabs {
        border-bottom: 5px solid #1c4d5a;
    }

    .table thead th {
        vertical-align: bottom;
        border: 0px solid #1c4d5a;
    }

</style>